import React, { useState } from 'react';
import ErrorBoundary from '../../../ErrorBoundary';
import { Input } from 'reactstrap';

const InputCellRender = ({
  rowData,
  columnName,
  updateRowValue,
  required = false,
  hasTouched = false,
}) => {
  const subProps = {};
  const value = rowData[columnName] || '';
  const [text, setText] = useState(value);

  const handlerChange = (event) => {
    const { value } = event.target;
    setText(value);
    updateRowValue(value, columnName);
  };

  const touced = required && hasTouched;

  if (touced) {
    subProps.title = 'Reason is required';
  }

  return (
    <div>
      <ErrorBoundary>
        <form>
          <Input
            type='text'
            name={columnName}
            id='comments'
            value={text}
            invalid={touced ? Boolean(!text) : false}
            onChange={handlerChange}
            autoComplete='off'
            className={`${required ? 'required' : ''}`}
            {...subProps}
          />
        </form>
      </ErrorBoundary>
    </div>
  );
};

export default InputCellRender;
