import React, { useEffect, useState} from 'react';
import ErrorBoundary from '../../../ErrorBoundary';
import moment from 'moment';
import { InputGroup, InputGroupText } from 'reactstrap';
import { Field, Formik } from 'formik';
import { dateFormate } from 'utils/columns';
import FormikDatePicker from 'components/common/Fields/FormikDatePicker';
import Calendar from 'assets/icon/calendar-icon.svg';
import ReactInputMask from 'react-input-mask';
import DatePicker from "react-datepicker";

const InputDateCellRender = ({
    rowData,
    columnName,
    updateRowValue,
    required = false,
    hasTouched = false,
}) => {
    const subProps = {};
    const value = rowData.endDate;
    console.log(value,"value")
    const [text, setText] = useState(null);
    const [endDate, setEndDate] = useState(null)
    const [hasDatePopupOpen, setHasDatePopupOpen] = useState(false);


    useEffect(() => {
        if (value !== null) {
            var newDate = dateFormate(new Date(value));
            // setText(newDate);
            setEndDate(newDate)
        }
        else{
            setEndDate(null)
        }
    }, []);


    const handleMouseLeave = () => {
       setHasDatePopupOpen(false)
    };


    const handleDateClick = (id) => {
        if (hasDatePopupOpen) {
           setHasDatePopupOpen(false)
            return;
        }
        const calendarButton = document.getElementById(id);
        if (calendarButton) {
            calendarButton.click();
           setHasDatePopupOpen(true)
        }
    };


    const handleEndDate = (endDate) => {
        if (endDate !== null) {
            const date = moment(endDate, 'MM/DD/YYYY', true);
            if (date.isValid()) {
                const validDate = date.toDate()
                setEndDate(validDate);

                const Endyear = validDate.getFullYear();
                const Endmonth = (validDate.getMonth() + 1).toString().padStart(2, '0');
                const Endday = validDate.getDate().toString().padStart(2, '0');
                const Date = `${Endyear}-${Endmonth}-${Endday}`
                updateRowValue(Date, columnName);
            }
            else {
                setEndDate(null)
            }
        } else {
            setEndDate(null);
            updateRowValue(null, columnName);
        }
        // updateRowValue(stDate, columnName);
    };

    console.log(endDate)
    const handleEndDateRaw = (e) => {
        var val = e.target.value;
        if (val == '__/__/____' && endDate !== null) {
            setEndDate(null);
            updateRowValue(null, columnName)
        }

        if (val !== undefined && val.length == 10 && val.indexOf("_") == -1) {
            handleEndDate(e.target.value);
            updateRowValue(val, columnName)
        }
    }

    const handleFocus = (id) => {
        setHasDatePopupOpen(true);
    };

    return (
        <div>
            <ErrorBoundary>
                <Formik>
                    <div className='required-date'>
                        <InputGroup onMouseLeave={handleMouseLeave}>
                            <DatePicker
                                id={columnName}
                                name={columnName}
                                onFocus={() => handleFocus(columnName)}
                                // onBlur={handleBlur}
                                selected={endDate}
                                minDate={new Date()}
                                onChange={(e) => handleEndDate(e)}
                                onChangeRaw={handleEndDateRaw}
                                dateFormat="MM/dd/yyyy"
                                portalId='root portal'
                                placeholderText='Select a date'
                                autoComplete='off'
                                customInput={
                                    <ReactInputMask mask="99/99/9999"
                                        value={endDate}
                                        onKeyDown={(e) => {
                                            e.preventDefault();
                                        }}
                                    // maskChar=''
                                    />
                                }
                                //ref={datePickerRef}
                                open={hasDatePopupOpen}  
                                shouldCloseOnSelect={false} 
                            />
                            <InputGroupText
                                onClick={ ()=>handleDateClick(columnName)}
                            >
                                <img src={Calendar} alt='' />
                            </InputGroupText>
                        </InputGroup>
                    </div>
                </Formik>
            </ErrorBoundary>
        </div>
    );
};

export default InputDateCellRender;
