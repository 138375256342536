import React from "react";
import DatePicker from "react-datepicker";
import { ErrorMessage } from "formik";
import { Label } from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import ReactInputMask from "react-input-mask";

const FormikDatePicker = ({

  field: { name, value },
  form: { touched, errors, setFieldValue },
  ...props
}) => {
  const { id, placeholder, label } = props;
  let formatedDate = "";

  if (value) {
    formatedDate = new Date(value);
  }
  else if (props.value) {
    formatedDate = new Date(props.value);
  }

  const handleChange1 = (Date) => {
    if (Date!== null) {
        const date = moment(Date, 'MM/DD/YYYY', true);
        if (date.isValid()) {
            setFieldValue(date.toDate());
        }
    } else {
        setFieldValue(null);
    }
  };
  
  const handleEndDateRaw = (e) => {
      var val = e.target.value;
      if (val !== undefined && val.length == 10 && val.indexOf("_") == -1) {
          handleChange1(e.target.value);
      }
  }

  const handleChange = (date) => {
    setFieldValue(name, date, true);
  };
  const handleKeyDown = (event) => {
    const allowedKeys = ['Backspace', 'Tab', 'ArrowLeft', 'ArrowRight', 'Delete', '/', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

    if (!allowedKeys.includes(event.key)) {
      event.preventDefault();
    }
  };

  return (
    <div className="form-control inner-shadow-field p-0">
      {label && (
        <Label for={name} className="d-block">
          {label}
        </Label>
      )}

      <DatePicker
        id={id}
        invalid={Boolean(touched[name] && errors[name])}
        selected={formatedDate}
        //onChange={handleChange}
        minDate={new Date()}
        onChange={handleChange1}
        onChangeRaw={handleEndDateRaw}
        name={name}
        dateFormat="MM/dd/yyyy"
        onKeyDown={handleKeyDown}
        maxLength={10}
        placeholderText={placeholder}
        {...props}
        className="border-0 w-100 date-picker"
        customInput={
          <ReactInputMask mask="99/99/9999"
            value={formatedDate}
          // maskChar=''
          />}
      />
        
      <ErrorMessage
        name={name}
        render={(msg) => <div className="text-danger font-size-12">{msg}</div>}
      />
      
    </div>
  );
};
export default FormikDatePicker;
