import React from "react";
import { components } from "react-select";



export const Option = (props) => {
    return (
        <div>
            <components.Option {...props}>
                <input
                    type="checkbox"
                     className='.css-tr4s17-option'
                    checked={props.isSelected}
                    onChange={() => null}
                />{" "}
                <label>{props.label}</label>
            </components.Option>
        </div>
    );
};
export const CustomOption = (props) => {
    const isSelectAll = props.data.value === 'selectAll'; // Assuming '0' is the value for "Select All"
    const isChecked = isSelectAll ? props.selectProps.isSelectAllChecked : props.isSelected;
  
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={isChecked}
            onChange={() => null} // Prevent default checkbox behavior
            className={isChecked ? 'highlighted-checkbox' : ''}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };

export const LimitedChipsContainer = ({ children, hasValue, ...props }) => {
    if (!hasValue) {
        
        return (
            <components.ValueContainer {...props}>
                {children}
            </components.ValueContainer>
        );
    }

    const CHIPS_LIMIT = 1;
    const [chips, otherChildren] = children;
    const overflowCounter = chips.slice(CHIPS_LIMIT).length;
    const displayChips = chips.slice(overflowCounter, overflowCounter + CHIPS_LIMIT);


    return (
        <components.ValueContainer {...props}>
            {displayChips}

            {otherChildren}

            {overflowCounter > 0 && `+ ${overflowCounter}`}


        </components.ValueContainer>
    );
};
