import React, { useEffect, useState } from 'react';
import { find } from 'lodash';
import { Field } from 'formik';

import useAxios from 'hooks/useAxios';
import FormikSelectField from 'components/common/Fields/FormikSelectField';

const CoveredEntity = ({ onChange, ...rest }) => {
  const API = useAxios();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const fetchCoveredEntity = async () => {
    setLoading(true);
    try {
      const { data } = await API.get('RCMConfig/coveredentity/list', {
        hasDefaultURL: true,
      });
      setLoading(false);
      setData(data);

      if (data) {
        if (rest.value) {
          if (rest.value) {
            const entity = find(data, {
              coveredEntityID: parseInt(rest.value),
            });
            onChange(rest.value, entity);
          } else {
            onChange(rest.value, '');
          }
        }
      }
    } catch (err) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCoveredEntity();
  }, []);
  const coveredEntity = loading ? [] : data || [];

  const handleChange = ({ target: { value } }) => {
    if (value) {
      const entity = find(coveredEntity, {
        coveredEntityID: parseInt(value),
      });
      onChange(value, entity);
    } else {
      onChange(value, '');
    }
  };
  return (
    <Field
      name='coveredEntityName'
      type='text'
      component={FormikSelectField}
      options={coveredEntity}
      defaultoption='--Select--'
      className='required'
      keys={{
        id: 'coveredEntityID',
        label: 'coveredEntityName',
      }}
      onChange={handleChange}
      {...rest}
    />
  );
};

export default CoveredEntity;
