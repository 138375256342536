import React from 'react';
import moment from 'moment';
import InputCellRender from 'components/reactStrapTable/components/tableCellRender/InputCellRender';
import CheckBoxCellRender from 'components/reactStrapTable/components/tableCellRender/CheckBoxCellRender';
import CommnetsCellRender from 'components/reactStrapTable/components/tableCellRender/CommnetsCellRender';
import StatusCellRender from 'components/reactStrapTable/components/tableCellRender/StatusCellRender';
import InputDateCellRender from 'components/reactStrapTable/components/tableCellRender/InputDateCellRender';
import InputNumberCellRender from 'components/reactStrapTable/components/tableCellRender/inputNumberCellRender';

const CellEditRender = (props) => {
  const {
    row,
    col,
    alwaysEditableCheckbox,
    formatterType,
    onChange,
    hasTouched = false,
    disabled = false,
    requiredFields = [],
    editPhoneNumber,
  } = props;
  const { edit, dataField: columnName } = col;

  const handleStatusChange = (claimStatusID) => {
    onChange(claimStatusID, 'claimStatusID');
  };

  if (row?.isEdit && edit) {
    if (formatterType === 'checkbox') {
      return !row[columnName] || alwaysEditableCheckbox ? (
        <CheckBoxCellRender
          rowData={row}
          columnName={columnName}
          updateRowValue={onChange}
        />
      ) : (
        row[columnName]
      );
    }
    if (formatterType === 'textarea') {
      return (
        <CommnetsCellRender
          rowData={row}
          columnName={columnName}
          updateRowValue={onChange}
        />
      );
    }
    if (formatterType === 'statusList') {
      return (
        <StatusCellRender
          rowData={row}
          hasTouched={hasTouched}
          updateRowValue={handleStatusChange}
          required={requiredFields.includes(formatterType)}
        />
      );
    }
    if (formatterType === 'date') {
      return (
        <InputDateCellRender
          rowData={row}
          columnName={columnName}
          updateRowValue={onChange}
        />
      );
    }
    if (formatterType === 'number') {
      return (
        <InputNumberCellRender
          rowData={row}
          columnName={columnName}
          updateRowValue={onChange}
          required={true}
          disabled={disabled}
          editPhoneNumber={editPhoneNumber}
        />);
    }
    return (
      <InputCellRender
        rowData={row}
        columnName={columnName}
        updateRowValue={onChange}
        hasTouched={hasTouched}
        required={requiredFields.includes('text')}
      />
    );
  } else if (formatterType === 'date') {
    if (row[columnName]) {
      return (
        <span>{moment(new Date(row[columnName])).format('MM/DD/YYYY')}</span>
      );
    }
    return <>{'-'}</>;
  }
  return <>{row[columnName] || '-'}</>;
};

export default CellEditRender;
