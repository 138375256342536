import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, Input, FormGroup, Label, Row, Col } from 'reactstrap';
import ErrorBoundary from '../../../ErrorBoundary';
import { NumericFormat } from 'react-number-format';

const InputNumberCellRender = ({
    rowData,
    columnName,
    updateRowValue,
    required = false,
    hasTouched = false,
    disabled = false,
    editPhoneNumber
}) => {
    const subProps = {};
    const value = rowData[columnName] || editPhoneNumber == false ? rowData[columnName] : 0;
    const [text, setText] = useState(value);

    const handlerChange = (event) => {
        const { value } = event.target;
        setText(value);
        updateRowValue(value, columnName);
    };

    const touced = required && hasTouched;

    if (touced) {
        subProps.title = 'Reason is required';
    }

    return (
        <div>
            <ErrorBoundary>
                <FormGroup>
                    <NumericFormat
                        type='text'
                        disabled={disabled}
                        name={columnName}
                        id={columnName}
                        decimalScale={2}
                        value={text}
                        maxlength={editPhoneNumber == false ? '10' : ''}
                        style={{ marginTop: editPhoneNumber == false ? 14 : '' }}
                        onChange={handlerChange}
                        autoComplete='off'
                        className={editPhoneNumber == false ? 'form-control' : `${required ? 'required form-control' : ''}`}
                        {...subProps}
                    />
                </FormGroup>
            </ErrorBoundary>
        </div>
    );
};

export default InputNumberCellRender;
