import React from 'react';
// "EXT", "ACM", "SUPADM"
export const ModuleAccess = (module, permission) => {
    var modules = {
        'MCM': {
            'EXT': ["ADD"],
            'ACM': ["AUTO"],
            'SUPADM': [""],

        },
    };

    const { access_token } =
        JSON.parse(sessionStorage.getItem('pharmaforce')) || {};
    let userRoles = [];
    if (access_token) {
        let jwtData = access_token.split('.')[1]
        let decodedJwtJsonData = window.atob(jwtData)
        let decodedJwtData = JSON.parse(decodedJwtJsonData)
        userRoles = decodedJwtData['roles'];
    }

    //console.log(module);
    var roles = modules[module];
    //console.log(roles);
    var permissions = [];
    if (Array.isArray(userRoles)) {
        for (var i = 0; i < userRoles.length; i++) {
            var userRole = userRoles[i];
            //console.log(userRole);
            var userper = roles[userRole];
            //console.log(userper);
            permissions = permissions.concat(userper);
        }
    }
    else {
        var userper = roles[userRoles];
        if (userper)
            permissions = permissions.concat(userper);
    }

    //console.log(permissions);
    if (!permission)
        return permissions.length > 0;
    return permissions.indexOf(permission) != -1;
}