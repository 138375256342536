import ReactStrapTable from 'components/reactStrapTable';
import React, { useEffect, useState } from 'react'
import EditIcon from 'assets/icon/icon-icon-options-edit.svg';

import EditHoverIcon from 'assets/icon/icon-icon-options-edit_hover.svg';
import HoverImage from 'react-hover-image/build';
import { Button, Col, FormGroup, Input, InputGroup, InputGroupText, Label, Row } from 'reactstrap';
import { dateFormate, currencyFormat, cellRendererCheck } from 'utils/columns';
import CancleIcon from 'assets/icon/icon-icon-options-not-eligible.svg';
import Calendar from 'assets/icon/calendar-icon.svg';
import SaveIcon from 'assets/icon/icon-icon-options-order-complete.svg';
import CellEditRender from 'components/reactStrapTable/components/CellEditRender';
import CancleHoverIcon from 'assets/icon/icon-icon-options-not-eligible-hover.svg';
import SaveHoverIcon from 'assets/icon/icon-icon-options-order-complete-hover.svg';
import { Field, Formik } from 'formik';
import ReactSelect from 'react-select';
import { cloneDeep, find } from 'lodash';

import FormikDatePicker from 'components/common/Fields/FormikDatePicker';
// import { faAngleUp } from "@fortawesome/free-solid-svg-icons";
// import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import reset from 'assets/icon/reset.svg';
import search from 'assets/icon/search.svg';
import FormikSelectField from 'components/common/Fields/FormikSelectField';
import useAxios from 'hooks/useAxios';
import useContextService from 'hooks/useContextService';
import Loader from 'components/Loader';




let hasDatePopupOpen = false;

let tableDataBackup = [
    {
        chainPharmacy: "123445",
        pharmacy: "123445",
        npi: "123445",
        ncpdPandNABP: "123445",
        pharmacyAddress: "123445",
        pharmacyLiveDate: "123445",
        pharmacyTerminationDate: "123445",
        claimsProcessDate: "123445",

    }
];
let rowForEdit = {};
let updatedFields = {};
const application = [
    { applicationid: 101, applicationName: 'Contract Pharmacy',encryptedappid:"MjFnUDZITDUweWNqL3plcG9MVmZoZz09" }
  ]
const SearchContainer = (props) => {
    const { onSearch, onReset,Loading, selectedcoveredEntity } = props;
    const {
        states: { configuration },
        setStates,
        resetStates,
    } = useContextService();
    // const validationSchema = Yup.object({
    //     coveredEntityID: Yup.string().required('Please select covered entity'),

    // });

    const API = useAxios();

    const [data, setData] = useState([]);
    const [showDetails, setShowDetails] = useState(true)
    const [ceid, setceid] = useState()
    const [appid, setAppid] = useState(application[0].encryptedappid)
    const [reportType, setReportType] = useState([])
    const [reportid, setReportid] = useState()
    const [coveredEntityData, setCoveredEntityData] = useState([])
    const [focusedInput, setFocusedInput] = useState('');
    const [error, setError]= useState(false)
    const [isSubmited, serIsSubmitted] = useState(false);
    const [loading, setLoading]= useState(false)
    const { searchTerms, }=configuration;
    // let {

    //     coveredEntityID,
    // } = searchTerms;

    // const [collapseSider, setcollapseSider] = useState(false);
    // const [collIcon, setCollIcon] = useState(faAngleUp);

    // const toggleSider = () => {
    //     setcollapseSider(!collapseSider);
    //     if (!collapseSider) {
    //         setCollIcon(faAngleDown);
    //     }
    //     else {
    //         setCollIcon(faAngleUp);
    //     }
    // }
    const updateStates = (configuration) => {
        setStates('configuration', configuration);
    };
    
    const renderEditButton = () => {
        // if (hasPermission('EDT'))
        return <Button
            className='text-primary p-0 text-decoration-none'
            color='link'
            onClick={() => ""}
            title='Edit Covered Entity'

        >
            <HoverImage src={EditIcon}
                hoverSrc={EditHoverIcon}
                alt='' />
        </Button>
    }
    const fetchCoveredEntities = async () => {
        setLoading(true)
        try {
          const { data } = await API.get(`api/CP/configured`
              , {
                hasDefaultURL: true,
            }
          );
          setLoading(false)
          if (data) {
            setCoveredEntityData(data)
          }
        } catch (error) {
          'failed to load data'
          setLoading(false)
        }
      };
      useEffect(() => {
        fetchCoveredEntities();
        // fetchPharmacy();
      }, [selectedcoveredEntity])
      useEffect(()=>{
        fetchReportType();
      },[appid])
    const cellRenderEdit = (rowData) => {

        if (rowData.isEdit) {
            return (
                <div className='d-flex align-items-center'>
                    <Button
                        className='text-primary p-0 text-decoration-none'
                        color='link'
                        onClick={savePanelUpdateRow}
                    >
                        <HoverImage
                            key='SaveIcon'
                            src={SaveIcon}
                            hoverSrc={SaveHoverIcon}
                            alt=''
                        />
                    </Button>
                    <Button
                        className='text-primary p-0 text-decoration-none'
                        color='link'
                        onClick={() => editRow(rowData, false)}
                    >
                        <HoverImage
                            key='CancelIcon'
                            src={CancleIcon}
                            hoverSrc={CancleHoverIcon}
                            alt=''
                        />
                    </Button>
                </div>
            );
        } else {

            return (
                <div className='d-flex align-items-center'>
                    {renderEditLButton1(rowData)}

                </div>
            );
        }
    };
    const renderEditLButton1 = (rowData) => {
        // if (hasPermission('EPLS'))
        return <Button
            className='text-primary p-0 text-decoration-none'
            color='link'

            onClick={() => editRow(rowData)}
            title='Edit Location'


        >
            <HoverImage src={EditIcon}
                hoverSrc={EditHoverIcon}
                alt='' />
        </Button>
    }
    const editRow = (rowData, isEdit = true) => {

        let tempData = [...data];
        tempData = tempData.map((row) => {
            row.isEdit = false;
            if (row.ceServiceLocationID === rowData?.ceServiceLocationID && isEdit) {
                row.isEdit = isEdit;
                rowForEdit = row;
            }
            return row;
        });
        // setTouched('');
        // setSelectedStatus('');
        updatedFields = {};
        //  tableDataBackup = tempData;
        setData(tempData);
    };
    const savePanelUpdateRow = async () => {
    };
    const updateRowValue = (value, col) => {

        const tempRow = { ...rowForEdit };
        tempRow[col] = value;
        rowForEdit = tempRow;
    };
    const formatter = (row, col, formatterType, alwaysEditableCheckbox) => {

        return (
            <CellEditRender
                row={row}
                col={col}
                formatterType={formatterType}
                onChange={updateRowValue}
                alwaysEditableCheckbox={alwaysEditableCheckbox}
            />
        );
    };
 const handelAdd = () => {
        setShowDetails(false)
    }
    const handelCancel = () => {
        setShowDetails(true)
    }
    useEffect(() => {

    }, [showDetails])
    const handleMouseLeave = () => {
        hasDatePopupOpen = false;
    };
      const handleAppChange = (e) => {
        const { value } = e.target;
        setAppid(value)
        if(appid){
        fetchReportType(appid)
        }
        else{
          setReportType([])
          
        }
      }
      const fetchReportType = async () => {
        setLoading(true)
        try {
          const { data } = await API.get(`api/Report/types/${appid}`
              , {
                hasDefaultURL: true,
            }
          );
          setReportType(data)
    
        } catch (error) {
          'failed to load data'
        }
      };
      const handleCeChange = (e) => {

        const { value } = e.target;
        setceid(value);
        //searchTerms.coveredEntityIDs=value
        // purchaseOrder.searchTerms.orderStatusId = value;
        // updateStates(purchaseOrder);
      };
      const handleReportChange = (e) => {
        const { value } = e.target;
        setReportid(value);
       // searchTerms.ReportTypeId=value
      }
    const handleDateClick = (id) => {

        if (hasDatePopupOpen) {
            hasDatePopupOpen = false;
            return;
        }
        const calendarButton = document.getElementById(id);
        if (calendarButton) {
            calendarButton.click();
            hasDatePopupOpen = true;
        }
    };



  const handleFocus = (inputName) => {
    setFocusedInput(inputName);
  };

  const handleBlur = () => {
    setFocusedInput('');
  };

  const handleSubmit = async (val, actions,) => {
    let values = {};
    values.ApplicationId=appid,
    values.coveredEntityID=ceid,
    values.ReportTypeId=reportid

    // serIsSubmitted(true);

    // const { setSubmitting } = actions;
    //const values = cloneDeep(val);

    // if (coveredEntityID) values.coveredEntityID = coveredEntityID;

    configuration.search.currentPage = 1;
    configuration.search.order= 'desc'
    updateStates(configuration);
    if(appid=='' || appid==undefined || ceid=='' || ceid == undefined){
        setError(true)
      }
      else{
    await onSearch(values);
    setError(false)
      }
    // setSubmitting(false);

};

const handleReset = () => {
    setceid("");
    setReportid("");
    setError(false);
    onReset();
    // setcoveredEntityid('');
    //  configuration.coveredEntity = {};
    configuration.search.pageSize = 10
    configuration.search.order="desc"

     updateStates(configuration);
     resetStates('configuration');
};

    return (

        <div>
  {/* {Loading&&<Loader opacity={0.01}color='black'/>}  */}
            <Formik
               initialValues={searchTerms}
               onSubmit={handleSubmit}
              onReset={handleReset}
            //    validationSchema={validationSchema}
                 enableReinitialize={true}
                children={(props) => {
                     const { isSubmitting, handleSubmit, resetForm, setFieldValue, errors, touched } = props;
                    return (
                        <div>

                            <div >
                                <Row className='d-flex' style={{}}>



                                    <Col md={3} lg={3} sm={6} xs={6}  >
                                        {' '}
                                        <FormGroup>
                                       
                                            <Label
                                    htmlFor="Application" for='reportname'
                                    className={focusedInput === 'Application' ? 'label-focus' : 'required'}
                                  >Application <span>*</span></Label>
                                            <Field
                                                id='reportname'
                                                name='reportname'
                                                type='select'
                                                disabled
                                                placeholder="(All)"
                                                value={appid}
                                                autoComplete='off'
                                                onChange={(e) => handleAppChange(e)}
                                                component={FormikSelectField}
                                                options={application}
                                                defaultoption={application[0].applicationName}
                                                keys={{
                                                  id: 'applicationid',
                                                  label: 'applicationName',
                                                }}
      
                                                onFocus={() => handleFocus('Application')}
                                                onBlur={handleBlur}
                                            >
                                                <option value="">{"-Select-"}</option>
                                            </Field>
                                            {(error) && (appid == '' || appid === undefined) && <div className="text-danger f-12">
                                                Application Id is required </div>}
                                        </FormGroup>
                                    </Col>

                                    <Col md={3} lg={3} sm={6} xs={6}  >
                                        {' '}
                                        <FormGroup>
                                       
                                            <Label
                                    htmlFor="CoveredEntity" for='reportname'
                                    className={focusedInput === 'CoveredEntity' ? 'label-focus' : 'required'}
                                  >Covered Entity /OPAID <span>*</span></Label>
                                            {/* <Input
                                                id='reportname'
                                                name='reportname'
                                                type='select'
                                                placeholder="(All)"
                                                autoComplete='off'
                                                onFocus={() => handleFocus('CoveredEntity')}
                                                onBlur={handleBlur}
                                            >
                                                <option value="">{"-Select-"}</option>
                                            </Input> */}
                                             <Field
                                          className="required"
                                          name='covereEntity'
                                          type='text'
                                          value={ceid}
                                          onChange={(e) => handleCeChange(e)}
                                          component={FormikSelectField}
                                          options={coveredEntityData}
                                          onFocus={() => handleFocus('CoveredEntity')}
                                                onBlur={handleBlur}
                                          defaultoption='--select--'
                                          keys={{
                                            id: 'ceid',
                                            label: 'ceName',
                                          }}
                                        />
                                        {(error) && (ceid == '' || ceid === undefined) && <div className="text-danger f-12">
                                            Covered Entity name is required</div>}
                                        </FormGroup>
                                    </Col>

                                   
                                    <Col md={3} lg={3} sm={6} xs={6}  >
                                        {' '}
                                        <FormGroup>
                                           
                                            <Label
                                    htmlFor="ReportType" for='reportname'
                                    className={focusedInput === 'ReportType' ? 'label-focus' : ''}
                                  >Report Type</Label>
                                           <Field
                                          name='ReportType'
                                          type='text'
                                          value={reportid}
                                          onChange={ handleReportChange}
                                          component={FormikSelectField}
                                          options={reportType}
                                          onFocus={() => handleFocus('ReportType')}
                                                onBlur={handleBlur}
                                          defaultoption='--select--'
                                          keys={{
                                            id: 'reportTypeID',
                                            label: 'reportTypeName',
                                          }}
                                        />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                </div>
                                <div className='section-devider-border mt-2 mb-3'></div>
                
                           
                            <div hidden={""}>
                                <Row>
                                    <Col sm={0} className="d-flex" style={{ justifyContent: "flex-end" }}>
                                        <Label>{""}</Label>
                                        <Button
                                            // hidden={isAddPermission}
                                            // disabled={isSubmitting}
                                            color="primary"
                                            className="reset"
                                            onClick={handleReset}

                                        // disabled={!coveredEntityid}
                                        // hidden={!hasPermission("ADD")}
                                        >
                                            <span className='right_5'><img src={reset} alt="" /></span>
                                            Reset
                                        </Button>
                                        <Button
                                            // hidden={isAddPermission}
                                            // disabled={isSubmitting}
                                            color="primary"
                                            className="search"
                                            onClick={handleSubmit}

                                        // disabled={!coveredEntityid}
                                        // hidden={!hasPermission("ADD")}
                                        >
                                            <span className='right_5'> <img src={search} alt="" /></span>
                                            Search
                                        </Button>
                                    </Col>
                                </Row>
                            </div>




                        </div>);
                }}></Formik>



        </div>
    )
}

export default SearchContainer