import React, { useState, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import { Routes, Route, BrowserRouter, Outlet } from 'react-router-dom';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import './style/pfapp.scss';

import ConfigurationsTabs from 'pages/ConfigurationsTabs'
import Login from 'pages/Login';
import NotValidTab from 'pages/NotValidTab';
import { PrivateRoute } from 'routes/privateRoute';
import axios from 'axios';
import { ModuleAccess } from 'utils/moduleaccess';
import Configurations from 'pages/ConfigurationsTabs/Configurations/Index';

const App = () => {
  const [modules, setModules] = useState([])

  const fetchModules = async () => {
    const { token_type, access_token, profile } =
      JSON.parse(sessionStorage.getItem('pharmaforce')) || {};
    const { sub } = profile || {};
    try {
      if (access_token) {
        renderPurchaseOrder();
       
      }

      // const { data } = await axios.get(
      //   `${process.env.REACT_APP_ENDPOINT}/RCMConfig/app/modules`,
      //   {
      //     //params: { Id: sub },
      //     headers: {
      //       'Content-type': 'application/json',
      //       Authorization: `${token_type} ${access_token}`,
      //     },
      //   }
      // );
      // if (data) {
      //   setModules(data);
      // }

    } catch (error) {
      console.log(error.message);
    }
  };

  const ThemeSelector = async () => {
    const { token_type, access_token, profile } =
      JSON.parse(sessionStorage.getItem('pharmaforce')) || {};
    const { sub } = profile || {};
    try {
      import('./style/pfapp.scss');
    } catch (error) {
      console.log(error.message);
    }



    return (
      <>
      </>
    )
  }

  useEffect(() => {
    // ThemeSelector();
    // fetchModules();
  }, [])


    const renderPurchaseOrder = () => {
      return (<Route path='/Configurations' element={<ConfigurationsTabs/>} />)
    //let module = modules.filter(m => m.code == "PRD")
    // if (ModuleAccess("PRD", ''))
    //   return (<Route path='/providers' element={<Providers module={module[0]} />} />)
   }
  
  const renderLogin = () => {
    return (<Route index element={<Login />} />)
    
  }
  
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route element={<Outlet />}>
            <Route element={<PrivateRoute modules={modules} />}>
              {renderLogin()}
              {renderPurchaseOrder()}
            </Route>

            {/* catch all */}
            <Route path='*' element={<NotValidTab />} />
          </Route>
        </Routes>
      </BrowserRouter>
      <ToastContainer position='top-right' autoClose={3000} />
    </>
  );
};

export default App;
