import ErrorBoundary from 'components/ErrorBoundary';
import React, { useEffect, useState } from 'react'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Card, CardBody, Col, Container, Row, Button } from 'reactstrap'
import { getUpdatedFilterLatest, onExport } from 'utils/helper';
import Reports from './Reports/Index';
import Configurations from './Configurations/Index';
import SearchContainer from './Reports/SearchContainer/Index';
import SearchContainerConfig from "./Configurations/SearchContainer/Index"
import ReportsTable from './Reports/ReportsTable/Index';
import { cloneDeep, find } from 'lodash';
import ConfigurationTable from './Configurations/ConfigurationsTable/index';
// import { faAngleUp } from "@fortawesome/free-solid-svg-icons";
// import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import search from 'assets/icon/search.svg';
import plus from 'assets/icon/plus-icon.svg';
import Drawer from 'components/Drawer';
import AddConfigurations from './Configurations/AddConfigurations/Index';
import useContextService from 'hooks/useContextService';
import useAxios from 'hooks/useAxios';
import { ModuleAccess } from 'utils/moduleaccess';
//import axios from 'axios';
let filters = [];
let filterReports=[]

let configdata = []
// let reportsdata=[]
const ConfigurationsTabs = () => {
    const { token_type, access_token, profile } =
      JSON.parse(sessionStorage.getItem('pharmaforce')) || {};
    const { roles } = profile || {};
    const [key, setkey] = useState('reports');
    const {
        states: { configuration , reports},
        setStates,
    } = useContextService();
    

    const API = useAxios();

    const [reset, setReset] = useState(false);
    const [collapseSider, setcollapseSider] = useState(false);
    const [selectedcoveredEntity, setselectedCoveredEntity] = useState(false)
    const { search, coveredEntity, searchTerms, claimStatusID } = configuration;
    const {Search, SearchTerms}= reports

    // const [collIcon, setCollIcon] = useState(faAngleDown);
    const [data, setData] = useState([])
    const [reportsdata, setReportsData] = useState([])
    const [totalItem, setTotalItem] = useState(0);
    const [totalItemReport, setTotalItemReport] = useState(0);
    const [loading, setLoading] = useState(false)
    // const [appid, setAppId] = useState('')
    //const [ceid, setceid] = useState('')

    const [openPharmacy, setOpenPharmacy] = useState(false);
    const[resetFilter,setResetFilter]=useState(false)


    // useEffect(() => {
    //     if (searchTerms.coveredEntityIDs) 
    // }, []);

    const onKeyChange = (k) => {

        setkey(k);
        setReset(!reset);
    }
    const handleExport = async () => {


        const action = "api/Report/download/0";
        onExport(action, Search);
    };
    // const handlePageChange = (filter, haveColFilter) => {
    //     if (haveColFilter) {
    //         const filterObj = cloneDeep(search);
    //         filterObj.filters = [...filterObj.filters, ...filter.filters];
    //         filterObj.currentPage = filter.currentPage;
    //         filterObj.pageSize = filter.pageSize;

    //         configuration.search.currentPage = filter.currentPage;
    //         configuration.search.pageSize = filter.pageSize;
    //         configuration.search = { ...configuration.search };
    //         fetchClaimList(filterObj);

    //     } else {
    //         fetchClaimList(filter);
    //         configuration.search = { ...configuration.search, ...filter };
    //     }
    //     setStates('configuration', configuration);
    // };
    const manval = () => {

        setcollapseSider(!collapseSider);
        // if (!collapseSider) {

        //     setCollIcon(faAngleDown);
        // }
        // else {

        //     setCollIcon(faAngleUp);
        // }
    }

    const handelCancel = () => {
        setShowDetails(true)
    }
    const manvalPharmacy = () => {
        setOpenPharmacy(!openPharmacy);
    }
    const fetchConfiguration = async (filters) => {

        setLoading(true)
        try {

            //  if (filters.ApplicationId && filters.ReportTypeId &&filters.coveredEntityIDs) {
            const { data } = await API.post(`api/Report/reportConfigGrid`, filters,
                {
                    hasDefaultURL: true,
                });
            if (data) {
                // setcollapseSider(true);
                configdata.push(data.items);
                setData(data.items)
                setTotalItem(data.totalRecords);
                setLoading(false)
            } else {
                configdata = []
                setData([])
                setTotalItem(0);
                setLoading(false)
            }
            // }

        } catch (error) { setLoading(false) }
    };
    const fetchReports = async (filters) => {
        setLoading(true)

        try {

            //  if (filters.ApplicationId && filters.ReportTypeId &&filters.coveredEntityIDs) {
            const { data } = await API.post(`api/Report/reportGrid`, filters,
                {
                    hasDefaultURL: true,
                }
            );
            if (data) {
                // setcollapseSider(true);
                 //reportsdata.push(data.items);
                setReportsData(data?.items)
                setTotalItemReport(data.totalRecords);
                setLoading(false)
            } else {
                setReportsData([])
                setData([])
                setTotalItemReport(0);
                setLoading(false)
            }
            // }

        } catch (error) { setLoading(false) }
    };
    const handleSearch = async (filter, hasfilter) => {
        // if(hasfilter==true){
         
        //     filter.claimType=filter.claimType
            
            
        //     filter.frequency= filter.frequency
            
        //     filter.coveredEntityID=searchTerms.coveredEntityIDs
          // filter.ReportTypeId=SearchTerms.ReportTypeId
        //}
        const {
            coveredEntityID,
            ApplicationId,
            ReportTypeId,
            claimType,
            frequency

        } = filter;
        Object.keys(filter).map((key) => {
            const index = filters.findIndex(
                (item) => item.filterName === key && key != 'dateOfService'
            );
            filters.splice(index, 1);
        });
        if (coveredEntityID) {
            filters = getUpdatedFilterLatest("CEID", coveredEntityID, filters)
        }
        // if(ApplicationId){
        //    filters= getUpdatedFilterLatest("AplicationId",ApplicationId,filters)
        // }
        if (ReportTypeId) {
            filters = getUpdatedFilterLatest("reportTypeID", ReportTypeId, filters)
        }
        // if(claimType){
        //     filters=getUpdatedFilterLatest("ClaimType",claimType,filters)
        // }
        // if(frequency){
        //     filters=getUpdatedFilterLatest('Frequency',frequency,filters)
        // }

        const tableRes = document.querySelector('.table-responsive');
        tableRes.scrollLeft = 0;
        search.filters = filters;

        // search.CoveredEntityID = coveredEntityID;
        await fetchConfiguration(search);
    };
    const handelSearchReports = async (filter, hasfilter) => {
        // if(hasfilter==true){
        //     filter.status=filter.status
        //     filter.coveredEntityID=SearchTerms.coveredEntityIDs
           // filter.ReportTypeId=SearchTerms.ReportTypeId
        //}
//console.log(filter.coveredEntityID)
        const {
            coveredEntityID,
            ApplicationId,
            ReportTypeId,
            StartDate,
            EndDate,
            status,


        } = filter;
        Object.keys(filter).map((key) => {
            const index = filterReports.findIndex(
                (item) => item.filterName === key && key != 'dateOfService'
            );
            filterReports.splice(index, 1);
        });
        if (coveredEntityID) {
            filterReports= getUpdatedFilterLatest("CEID", coveredEntityID, filterReports)
        }
        // if(ApplicationId){
        //    filters= getUpdatedFilterLatest("AplicationId",ApplicationId,filters)
        // }
        if (ReportTypeId) {
            filterReports = getUpdatedFilterLatest("ReportTypeID", ReportTypeId, filterReports)
        }
        if (StartDate) {
        const year = StartDate.getFullYear();
            const month = (StartDate.getMonth() + 1).toString().padStart(2, '0');
            const day = StartDate.getDate().toString().padStart(2, '0');
            let StartDateUTC = `${month},${day},${year}`
            filterReports = getUpdatedFilterLatest("FromDate", StartDateUTC, filterReports, "startDate")
        }
        if (EndDate) {
            const endYear = EndDate.getFullYear();
            const endmonth = (EndDate.getMonth() + 1).toString().padStart(2, '0');
            const endday = EndDate.getDate().toString().padStart(2, '0');
            let EndDateUTC = `${endmonth},${endday},${endYear}`
            filterReports = getUpdatedFilterLatest("ToDate",EndDateUTC, filterReports, "endDate")
        }

        // if(status){
        //     filterReports=getUpdatedFilterLatest("StatusID",status,filterReports)
        // }
        const tableRes = document.querySelector('.table-responsive');
        tableRes.scrollLeft = 0;
        Search.filters = filterReports;

        // search.CoveredEntityID = coveredEntityID;
        await fetchReports(Search);
    };

    const refetch = () => {
        handleSearch(searchTerms);
    };
    const handleReset = () => {
        setcollapseSider(false);
        setData([]);
        //filters = []
        setTotalItem(0);
    };

    const handleResetReports = () => {
        setcollapseSider(false)
        setReportsData([]);
        // filters = []
        setTotalItemReport(0);
    };

    const handlecoveredEntity = () => {
        setselectedCoveredEntity((prev) => !prev)

    }
    const handlePageChange = (filter, haveColFilter) => {
        if (haveColFilter) {
            const filterObj = cloneDeep(search);
            filterObj.filters = [...filterObj.filters, ...filter.filters];
            filterObj.currentPage = filter.currentPage;
            filterObj.pageSize = filter.pageSize;

            configuration.search.currentPage = filter.currentPage;
            configuration.search.pageSize = filter.pageSize;
            configuration.search = { ...configuration.search };
            fetchConfiguration(filterObj);

        } else {
            if (totalItem > 0)
                fetchConfiguration(filter);

            configuration.search = { ...configuration.search, ...filter };

        }
        setStates('configuration', configuration);
    };
const hasPermission=(code)=>{
    return ModuleAccess("MCM",code)
}
    const handleReportPageChange = (filter, haveColFilter) => {
        if (haveColFilter) {
            const filterObj = cloneDeep(Search);
            filterObj.filters = [...filterObj.filters, ...filter.filters];
            filterObj.currentPage = filter.currentPage;
            filterObj.pageSize = filter.pageSize;

            reports.Search.currentPage = filter.currentPage;
            reports.Search.pageSize = filter.pageSize;
            reports.Search = { ...reports.Search };
    
            fetchReports(filterObj);


        } else {
            if (totalItemReport > 0)
                fetchReports(filter);

            reports.Search = { ...reports.Search, ...filter };

        }
        setStates('reports', reports);
    };

    return (
        <div>

            <Container fluid className='my-3 bin_info' >
                <div className='border-0 shadow-top card' hidden={collapseSider} >
                    <div className='card-body'>
                        {key == "configuration" ?
                            <div style={{ position: "relative" }}>
                                <Button style={{ padding: "10px 10px", position: "absolute", right: "0" }}
                                    // hidden={isAddPermission}
                                    // disabled={isSubmitting}
                                    color="primary"
                                    className="search"
                                    onClick={manvalPharmacy}

                                // disabled={ceid === '' || ceid == undefined || appid === '' || appid == undefined}
                                    hidden={hasPermission("ADD")}
                                >
                                    <span className='right_5'> <img src={plus} alt="" /></span>
                                    Add
                                </Button>
                            </div>
                            : ""}
                        <Tabs
                            id="controlled-tab-example"
                            activeKey={key}
                            // onSelect={(k) => setkey(k)}
                            onSelect={(k) => onKeyChange(k)}
                            className="mb-3"
                        >
                            <Tab eventKey="reports" title="Reports">
                                <SearchContainer onSearchReports={handelSearchReports}
                                 onReset={handleResetReports} 
                                 selectedcoveredEntity={selectedcoveredEntity} 
                                 />
                            </Tab>
                            {!roles?.includes("EXT")&&
                            <Tab eventKey="configuration" title="Configurations">
                                <SearchContainerConfig onSearch={handleSearch}
                                    onReset={handleReset}
                                    selectedcoveredEntity={selectedcoveredEntity}
                                    Loading={loading} />
                            </Tab>
}
                        </Tabs>
                    </div>
                </div>
                <div className='border-0 shadow-top card mt-3'>
                    <div className='section-devider-border card-body'>
                        {key == "reports" ?
                            <ReportsTable manval={manval}
                                collapseSider={collapseSider}
                                filters={Search}
                                loading={loading}
                                onPageChange={handleReportPageChange}
                                onSearch={handelSearchReports}

                                totalSize={totalItemReport}
                                reportsdata={reportsdata} /> :
                            <ConfigurationTable
                                loading={loading}
                                manval={manval}
                                collapseSider={collapseSider}
                                data={data}
                                filters={search}
                                onSearch={handleSearch}
                                onPageChange={handlePageChange}
                                totalSize={totalItem}
                                fetchConfiguration={fetchConfiguration}
                                 />}
                    </div>
                </div>
            </Container>
            <Drawer
                direction='end'
                isOpen={openPharmacy}
                toggle={manvalPharmacy}
                size='lg'
                title='Add Configuration'
            >
                <AddConfigurations
                    manvalPharacy={manvalPharmacy}
                    onSearch={handleSearch}
                    Loading={loading}
                    onSave={handlecoveredEntity}


                />            </Drawer>
        </div>
    )
}

export default ConfigurationsTabs