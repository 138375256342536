import React, { useEffect, useState } from 'react';
import {
  Navbar,
  NavbarBrand,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { isEmpty } from 'lodash';
import Logo from 'components/Logo';
import poweredBy from 'assets/images/powered_by_pharmaforce.svg';
import user from 'assets/icon/user-icon.svg';
import useOidc from 'hooks/useOidc';

const Header = () => {
  const { logout } = useOidc();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [hidePoweredBy, setHidePoweredBy] = useState(false);
  const navigate = useNavigate();
  const userName=sessionStorage.getItem('pharmaforce')
  const profileObject = JSON.parse(userName);
  //console.log(profileObject.profile,"userName")

  useEffect(() => {
    const { token_type, access_token, profile } =
      JSON.parse(sessionStorage.getItem('pharmaforce')) || {};
    const interval = setInterval(() => { }, 1000);

    const theme = sessionStorage.getItem('theme') || "PF";

    if (theme === "PF") setHidePoweredBy(true);

    if (!access_token) {
      navigate('/');
    }
    return () => {
      clearInterval(interval);
    };
  }, []);

  const fetchData = async () => {
    const { token_type, access_token, profile } =
      JSON.parse(sessionStorage.getItem('pharmaforce')) || {};
    const { sub } = profile || {};
    try {
      setLoading(true);
      const { data } = await axios.get(
        `${process.env.REACT_APP_ENDPOINT}/users/User/getUserDetails`,
        {
          params: { Id: sub },
          headers: {
            'Content-type': 'application/json',
            Authorization: `${token_type} ${access_token}`,
          },
        }
      );
      setData(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    // fetchData();
  }, []);

  const handleLogout = () => {
    sessionStorage.clear();
    logout();
  };

  return (
    <>
      <Navbar color='white' light className='py-1 app-header'>
        <div className='d-flex align-items-center'>
          <NavbarBrand href='/' className='me-0'>
            <Logo height={50} />
          </NavbarBrand>
          <h6 className='ps-3 ms-3 d-inline mx-1 mt-1 mb-0 mcm_hed'>
           ClaimsConnect
          </h6>
        </div>
        <div className='ms-auto powered-by'>
          <img src={poweredBy} title='' alt='' hidden={hidePoweredBy} />
        </div>

        {loading && (
          <Nav className='ps-4'>
            <UncontrolledDropdown nav className='profile-dropdown'>
              <DropdownToggle nav caret className='font-weight-500' style={{fontSize:"14px",}}>
                <span className=''><img style={{background: "#702C621A", padding: "7px", borderRadius: "50px", marginRight:"8px",}} src={user} alt="" /></span>
                {profileObject?profileObject.profile.username : ''}
              </DropdownToggle>
              <DropdownMenu className='shadow'>
                <DropdownItem onClick={handleLogout}>Logout</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        )}
      </Navbar>
    </>
  );
};

export default Header;
