import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Table, Input, Button, ButtonGroup, UncontrolledDropdown, DropdownItem, DropdownMenu, DropdownToggle, FormGroup, Label } from 'reactstrap';
import ColumnFilters from './components/ColumnFilters';
import Loader from 'components/Loader';
import collapse from 'assets/icon/collapse-icon.svg';
import collapseclose from 'assets/icon/collapse-icon-close.svg';
import filter from 'assets/icon/filters-icon.svg';
import exports from 'assets/icon/export-icon.svg';
import Select from 'react-select';
import downarrow from 'assets/icon/down-arrow.svg';
import Pagination from 'components/Pagination';
import Custom from 'components/custom/Index';
import leftArrowIcon from 'assets/icon/pf-arrow-left.svg';
import rightArrowIcon from 'assets/icon/pf-arrow-right.svg';
import ReportsTable from 'pages/ConfigurationsTabs/Reports/ReportsTable/Index';





let keysName = [];
let columnChangedFilters = [];
let isHaveColFilter = false;

let countryList = [
  { id: 1, name: "12 - Column" },
  { id: 2, name: "12 - Column" },
]

const ReactStrapTable = ({
  id,
  columns,
  data,
  fixedColumn,
  handleSort = () => '',
  loading,
  reset,
  loader,
  hasScrollArrow = true,
  onFilterCancel = () => '',
  onSearchColumnFilters = () => '',
  pageSize,
  onPageSizeChange = () => '',
  onPageSizeReportChange=()=>'',
  children,
  onExport,
  AllExport,
  slelectedExport,
  onSelectColoums,
  hasPagination,
  isConfigurationTable,
  onPageChange,
  onPageReportChange,
  currentPage,
  totalRecords,
  filters,
  reportFilters,
  isReportConfiguration,
  reportTotalRecords,
  reportPageSize,
  reportCurrentPage,
  researchNeededCount = 0,
  eligibleCount = 0,
  inEligibleCount = 0,
  hasNoEligibility = true,
  hasExportPermission = true,
  gridMsg = 'No record found',
  hasColselect = false,
  autoselectlist1,
  toggleSider,
  collapseSider
}) => {

  const [key, setKey] = useState('');
  const [resetField, setResetField] = useState(false);
  const [hasVisibleScrollArrow, setVisibleScrollArrow] = useState(false);
  const [hasLeftArrow, setLeftArrow] = useState(false);
  const [hasRightArrow, setRightArrow] = useState(false);
  const [rSelected, setRSelected] = useState('off');
  const [colFilterEnable, setColFilterEnable] = useState(false);
  const [sortKey, setSortKey] = useState('desc');
  const [SortKey1, setSortKey1]= useState('asc')
  const selectRef = useRef(null);


  // useEffect(() => {
  //   columnChangedFilters = [];
  // }, []);
  const getUpdateColum = () => {
    const updateCol = [];
    const col = columns[0];
    if (!col?.action && colFilterEnable) {
      updateCol.push({
        dataField: 'Options',
        text: 'Options',
        width: 80,
        show: false,
        disabled: true,
        hideColFilter: true,
        action: true,
      });
    }
    return [...updateCol, ...columns];
  };
  useEffect(() => {
    setKey('');
  }, [reset]);

  const removeSort = (keys) => {
    keysName.push(keys);
    const filter = keysName.filter((item) => item === keys);
    if (filter.length === 3) {
      setKey('');
      setSortKey('desc');
      keysName = [];
    } else
     {
      const sortObj = {
        order: sortKey === 'asc' ? 'asc' : 'desc',
        orderBy: keys,
      };
      setSortKey(sortObj.order);
      if (isHaveColFilter) {
        const apiFilter = {
          ...filters,
          ...sortObj,
          filters: [...columnChangedFilters],
        };
        // handleSort(filters, keys === "claimStatus" ? "claimID" : keys, true, columnChangedFilters);
        handleSort(apiFilter, true);
      } else {
        const apiFilter = { ...filters, ...sortObj };
        // handleSort(filters, keys === "claimStatus" ? "claimID" : keys);
        handleSort(apiFilter);
      }
    }
  };
  const removeSortDown = (keys) => {
    keysName.push(keys);
    const filter = keysName.filter((item) => item === keys);
    if (filter.length === 3) {
      setKey('');
      setSortKey1('asc');
      keysName = [];
    } else
     {
      
      const sortObj = {
        order: SortKey1=== 'desc' ? 'desc' : 'asc',
        orderBy: keys,
      };
      setSortKey1(sortObj.order);
      if (isHaveColFilter) {
        const apiFilter = {
          ...filters,
          ...sortObj,
          filters: [...columnChangedFilters],
        };
        // handleSort(filters, keys === "claimStatus" ? "claimID" : keys, true, columnChangedFilters);
        handleSort(apiFilter, true);
      } else {
        const apiFilter = { ...filters, ...sortObj };
        // handleSort(filters, keys === "claimStatus" ? "claimID" : keys);
        handleSort(apiFilter);
      }
    }
  };

  const  onColHeaderClickTop = (col) => {
    if (col.disabled) return;
    setKey(col.dataField);
   // removeSort(col.dataField);
  };

  const onColHeaderClickDown = (col) => {
    if (col.disabled) return;
    setKey(col.dataField);
    //removeSortDown(col.dataField);
  };
  const [activeColumn, setActiveColumn] = useState(null);

const onColHeaderClick = (col) => {
  setActiveColumn(col.dataField);
  // Add any additional logic for sorting or other actions here
};

  const headerRenderer = () => {
    const cols = getUpdateColum().map((col, index) => {
      const isActive = key === col.dataField;
      
      // const sortingClass = isActive && totalRecords >0
      //   ? `active-sorting-${sortKey === 'asc' ? 'down' : 'up'} active-sorting-${SortKey1 === 'desc' ? 'up' : 'down'}`
      //   : '';
    
    //const cols = getUpdateColum().map((col, index) => {
      return (
        <th
          key={col.dataField}
         // className={sortingClass}
            // key === col.dataField
            //   ? `active-sorting-${sortKey === 'asc' ? 'down' : 'up'}`
            //   :  key === col.dataField ? ` active-sorting-${SortKey1 === 'desc' ? 'up' : 'down'}`:''}
          //width={col.width || 100}
         // onClick={() => onColHeaderClick(col)} 
          // onClick={() => {
          //   col.sort ? onColHeaderClick(col) : '';
          // }}
          hidden={!col.show}
        >
          {col.text}
          {col.sort == true ?
          
            <span className='' style={{ marginLeft: "5px", position: "relative", verticalAlign: "sub", }}>
              <img src={downarrow} alt="" onClick={() => {
                 col.sort  && totalRecords >0 ? onColHeaderClickTop(col) : '';
              }} />
              <img className='filters_col' src={downarrow} alt=""
                onClick={() => {
                  col.sort  && totalRecords >0 ? onColHeaderClickDown(col) : '';
                }}
              />
            </span> : ""}
        </th>
      );
    });
    return (
      <thead key='headerrow'>
        <tr>
          {/* {colFilterEnable && columns[0].action ? <th width={100}>Options</th> : ""} */}
          {cols}
          {/* {colFilterEnable && <th width={100}>Options</th>} */}
        </tr>
      </thead>
    );
  };
  const renderRow = () => {
    if (data.length) {
      const rows = data.map((row, index) => {
        const rowItem = getUpdateColum().map((col) => {
          // if (index === 0 && colFilterEnable) {
          //   return <th key="option" width={100}></th>;
          // }
          if (col.cellRenderer) {
            return (
              <td
                key={col.dataField}
                width={col.width || 100}
                className={col.edit ? 'py-1' : col.className || ''}
                hidden={!col.show}
              >
                {col.cellRenderer(row, col)}
              </td>
            );
          }
          return (
            <td key={col.dataField} width={col.width || 100} hidden={!col.show}>
              {row[col.dataField]}
            </td>
          );
        });
        return (
          <tr key={index}>
            {rowItem}
            {/* {colFilterEnable && <td width={100}></td>} */}
          </tr>
        );
      });
      return rows;
    } else {
      return (
        <tr>
          <td colSpan={getUpdateColum().length} className='text-center'>
            {loading ? 'Loading...' : gridMsg}
          </td>
        </tr>
      );
    }
  };

  const handleColumnFilterChange = ({ target: { value } }, col, type, filterType) => {
    const { dataField } = col;
    const obj = {
      filterName: dataField,
      filterType: filterType === "" ? 1 : filterType,
    };
    if (!value) {
      const filter = columnChangedFilters?.filter(
        (item) => item.filterName !== dataField
      );
      columnChangedFilters = filter;
      return;
    }
    const findObj = find(columnChangedFilters, {
      filterName: dataField,
    });
    if (findObj) {
      if (type === 'field') {
        findObj.filterValue = value;
      } else {
        findObj.filterType = parseInt(value);
      }
    } else {
      if (type === 'field') {
        obj.filterValue = value;
      } else {
        obj.filterType = parseInt(value);
      }
      const colFilters = [...columnChangedFilters, ...[obj]].filter(
        (item) => item.filterValue
      );
      columnChangedFilters = colFilters;
    }
  };

  const onSearchFilter = () => {
    isHaveColFilter = true;
    onSearchColumnFilters(columnChangedFilters);
  };

  const onCancelFilter = () => {
    isHaveColFilter = false;
    onFilterCancel();
    setResetField((pre) => !pre);
    columnChangedFilters = [];
  };

  const columnFilters = () => {
    var ids = [];
    if (hasColselect) {
      if (autoselectlist1.length > 0)
        for (var x in autoselectlist1) {
          ids.push(autoselectlist1[x].text)
        }
    }

    return (
      <tr style={{ position: "sticky", top: "50px", "zIndex": 11 }}>
        {getUpdateColum()?.map((col, index) => {
          if (index === 0) {
            return (
              <td key='action'>
                <span className='pe-2 cursor-pointer' onClick={onSearchFilter}>
                  <img src={searchIcon} alt='' />
                </span>
                <span className='cursor-pointer' onClick={onCancelFilter}>
                  <img src={crossIcon} alt='' />
                </span>
              </td>
            );
          }
          if (col.hideColFilter) {
            return <td key={col.dataField}></td>;
          }
          return (
            <td key={col.dataField} hidden={hasColselect ? (autoselectlist1.length > 0 ? !ids.includes(col.text) : false) : false}>
              <ColumnFilters
                col={col}
                onColumnFilterChange={handleColumnFilterChange}
                reset={resetField}
              />
            </td>
          );
        })}
      </tr>
    );
  };

  const getCurrentTableElement = () =>
    document
      .querySelector(`#${id}`)
      .getElementsByClassName('table-responsive')[0];

  const handleMouseEnter = () => {
    if (!id) return;
    const element = getCurrentTableElement();
    const rightScroll =
      element.scrollWidth - element.scrollLeft - element.clientWidth;
    if (rightScroll <= 10 && element.scrollLeft <= 10) return;
    if (element.scrollLeft <= 0) {
      setLeftArrow(false);
      setRightArrow(true);
    } else {
      setLeftArrow(true);
    }
    if (rightScroll <= 0) {
      setLeftArrow(true);
      setRightArrow(false);
    }
    setVisibleScrollArrow(true);
  };

  const handleMouseLeave = () => {
    setVisibleScrollArrow(false);
  };

  const scroll = (direction) => {
    const element = getCurrentTableElement();
    slideScroll(element, direction, 50, 500, 70);
  };

  const slideScroll = (element, direction, speed, distance, step) => {
    let scrollAmount = 0;
    var slideTimer = setInterval(function () {
      element.behavior = 'smooth';
      if (direction == 'left') {
        element.scrollLeft -= step;
      } else {
        element.scrollLeft += step;
      }
      scrollAmount += step;
      if (scrollAmount >= distance) {
        window.clearInterval(slideTimer);
      }
    }, speed);


    setTimeout(() => {
      const el = getCurrentTableElement();
      const rightScroll = el.scrollWidth - el.scrollLeft - el.clientWidth;
      if (el.scrollLeft === 0) {
        setLeftArrow(false);
      } else {
        setLeftArrow(true);
      }
      if (rightScroll <= 0) {
        setRightArrow(false);
      } else {
        setRightArrow(true);
      }
    }, 500);
  };

  const renderScrollArrowButtons = () => {
    if (hasScrollArrow && hasVisibleScrollArrow && data && data.length) {
      return (
        <>
          {hasLeftArrow && (
            <Button
              onClick={() => scroll('left')}
              color='link'
              className={`left-table-button ${!hasLeftArrow ? 'opacity-25' : ''
                }`}
              disabled={!hasLeftArrow}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <img
                src={leftArrowIcon}
                alt='leftArrowIcon'
                height={60}
                width={60}
              />
            </Button>
          )}
          {hasRightArrow && (
            <Button
              onClick={() => scroll('right')}
              color='link'
              className={`right-table-button ${!hasRightArrow ? 'opacity-25' : ''
                }`}
              disabled={!hasRightArrow}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <img
                src={rightArrowIcon}
                alt='rightArrowIcon'
                height={60}
                width={60}
              />
            </Button>
          )}
        </>
      );
    }
    return null;
  };

  const onClickFilter = (value) => {
    if (value === 'on') {
      setRSelected(value);
      setColFilterEnable(true);
    } else {
      setRSelected(value);
      setColFilterEnable(false);
    }
    columnChangedFilters = [];
  };

  const onHandlePageChange = (page) => {
    const pageFilter = { ...filters, currentPage: page };
    if (isHaveColFilter) {
      let colFilterOn = { ...pageFilter, filters: columnFilters };
      return onPageChange(colFilterOn, true, columnChangedFilters);
    }
    onPageChange(pageFilter);
  };

  const handlePageSizeChange = (e) => {
    const { value } = e.target;
    const pageFilter = {
      ...filters,
      currentPage: 1,
      pageSize: parseInt(value),
    };
    if (isHaveColFilter) {
      let colFilterOn = { ...pageFilter, filters: columnFilters };
      return onPageSizeChange(colFilterOn, true, columnChangedFilters);
    }
    onPageSizeChange(pageFilter);
  };

  // const onHandleReportPageChange = (page) => {

  //   const pageFilter = { ...filters, currentPage: page };
  //   if (isHaveColFilter) {
  //     let colFilterOn = { ...pageFilter, filters: columnFilters };
  //     return onPageChange(colFilterOn, true, columnChangedFilters);
  //   }
  //   onPageChange(pageFilter);
  // };

  // const handlePageReportSizeChange = (e) => {
  //   const { value } = e.target;
  //   const pageFilter = {
  //     ...filters,
  //   currentPage: 1,
  //   pageSize: parseInt(value),
  //   };
  //   if (isHaveColFilter) {
  //     let colFilterOn = { ...pageFilter, filters: columnFilters };
  //     return onPageChange(colFilterOn, true, columnChangedFilters);
  //   }
  //   onPageChange(pageFilter);
  // };


  return (
    <>
      {/* <div className="d-flex justify-content-between section-devider-border pb-2"> */}
        {/* <h3 className='font-20'>Search Results</h3>
        <span style={{ cursor: "pointer" }} onClick={toggleSider}>
          <img collapseSider src={!collapseSider ? collapse : collapseclose} className="" />
        </span> */}
      {/* </div>
      {pageSize && (
        <Row className='py-3'>
          <div className="col-auto" style={{ borderRight: "1px solid #DDDDDD", paddingRight: "1rem", }}>
            <Button
              // hidden={isAddPermission}
              // disabled={isSubmitting}
              color="primary"
              className="filter"
              onClick={""}

            // disabled={!coveredEntityid}
            // hidden={!hasPermission("ADD")}
            >
              <span className='right_5'> <img src={filter} alt="" /></span>
              Filters
            </Button>

          </div>
          <Col className="p-0">




            <div className='d-flex justify-content-start'>
              <div className='table-dropdown' style={{ display: "inline-flex" }}>
                <FormGroup className='controls ms-3 mb-0'>
                  <Custom defaultText="Report Type"
                    optionsList={countryList}
                  />
                </FormGroup>
                <FormGroup className='controls ms-3 mb-0'>
                  <Custom defaultText="Report Status"
                    optionsList={countryList}
                  />
                </FormGroup>

              </div>

            </div>


          </Col>

          <div className="col-auto">
            <Button
              // hidden={isAddPermission}
              // disabled={isSubmitting}
              color="primary"
              className="btn-export"
              onClick={""}

            // disabled={!coveredEntityid}
            // hidden={!hasPermission("ADD")}
            >
              <span className='right_5'> <img src={exports} alt="" /></span>
              Export
            </Button>

          </div> */}












          {/* <Col xs='auto'>
            {hasColselect && (
              <Button
                size='sm'
                color="primary"
                className='ms-auto'
                onClick={onSelectColoums}
                style={{ height: 35 }}

              >
                Column selector
              </Button>
            )}
          </Col> */}

        {/* </Row> */}
      {/* )} */}
      <div className="d-flex justify-content-between section-devider-border pb-2">
        <h3 className='font-20'>Search Results 
        {totalRecords >0 &&
            <span className='f-18 text-primary font-weight-700 ms-1'>({totalRecords})</span>}
        </h3>
        <span style={{ cursor: "pointer" }} onClick={toggleSider}>
          <img collapseSider src={!collapseSider ? collapse : collapseclose} className="" />
        </span>
      </div>
      {pageSize && (
        <Row className='py-3'>
          <div className="col-auto" style={{ borderRight: "1px solid #DDDDDD", paddingRight: "1rem", }}>
            <Button
              // hidden={isAddPermission}
              // disabled={isSubmitting}
              color="primary"
              className="filter"
              onClick={""}

            // disabled={!coveredEntityid}
            // hidden={!hasPermission("ADD")}
            >
              <span className='right_5'> <img src={filter} alt="" /></span>
              Filters
            </Button>

          </div>
          <Col className="p-0">




            <div className='d-flex justify-content-start'>
              <div className='table-dropdown' style={{ display: "inline-flex" }}>
                <FormGroup className='controls ms-3 mb-0'>
                  <Custom defaultText="Report Type"
                    optionsList={countryList}
                  />
                </FormGroup>
                <FormGroup className='controls ms-3 mb-0'>
                  <Custom defaultText="Report Status"
                    optionsList={countryList}
                  />
                </FormGroup>

              </div>

            </div>


          </Col>

          <div className="col-auto">
            <Button
              // hidden={isAddPermission}
              // disabled={isSubmitting}
              color="primary"
              className="btn-export"
              onClick={""}

            // disabled={!coveredEntityid}
            // hidden={!hasPermission("ADD")}
            >
              <span className='right_5'> <img src={exports} alt="" /></span>
              Export
            </Button>

          </div>










          

          {/* <Col xs='auto'>
            {hasColselect && (
              <Button
                size='sm'
                color="primary"
                className='ms-auto'
                onClick={onSelectColoums}
                style={{ height: 35 }}

              >
                Column selector
              </Button>
            )}
          </Col> */}

        </Row>
      )}
      <div
        className='rounded-table'
        ref={selectRef}
        id={id}
        onMouseLeave={handleMouseLeave}
        onMouseMove={handleMouseEnter}
      >
        {!loader && loading && <Loader opacity={0.01} color='black' />}
        {renderScrollArrowButtons()}
        <Table
          responsive
          id='reactstrap-table'
          className={`table table-striped theme-table ${fixedColumn && 'fixed-column-table'
            }`}
          style={{ scrollBehavior: 'smooth' }}
        >
          {headerRenderer()}
          <tbody>
            {colFilterEnable && columnFilters()}
            {renderRow()}
          </tbody>
        </Table>
      </div>
      {/* { isConfigurationTable && <>  */}
      <Input
        type='select'
        onChange={handlePageSizeChange}
        bsSize='sm'
        className='display-select d-inline-block'
        value={pageSize}
      >
        <option value={10}>10</option>
        <option value={25}>25</option>
        <option value={50}>50</option>
        <option value={100}>100</option>
      </Input>

     {/* {hasPagination && ( */}
      <Pagination
        onPageChange={onHandlePageChange}
        currentPage={currentPage}
        totalRecords={totalRecords}
        pageSize={pageSize}
        hasNoEligibility={hasNoEligibility}
        researchNeededCount={researchNeededCount}
        eligibleCount={eligibleCount}
        inEligibleCount={inEligibleCount}
      />
       {/* </>
}  */}

 {/* {isReportConfiguration &&
<>   */}
{/* <Input
        type='select'
        onChange={handlePageReportSizeChange}
        bsSize='sm'
        className='display-select d-inline-block'
        value={pageSize}
      >
        <option value={10}>10</option>
        <option value={25}>25</option>
        <option value={50}>50</option>
        <option value={100}>100</option>
      </Input>  */}

     {/* {hasPagination && ( */}
       {/* <Pagination
        onPageChange={onHandleReportPageChange}
        onPageReportChange={onHandleReportPageChange}
        currentPage={currentPage}
        totalRecords={totalRecords}
        pageSize={pageSize}
        hasNoEligibility={hasNoEligibility}
        researchNeededCount={researchNeededCount}
        eligibleCount={eligibleCount}
        inEligibleCount={inEligibleCount}
      />
 </>
  
}  */}
      {/* )} */}

    </>
  );
};

export default ReactStrapTable;
