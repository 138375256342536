import React, { useState, useEffect } from 'react'
import { Card, CardBody, Container, Row, Col, FormGroup, Label, Input, Button, ButtonGroup, InputGroup, InputGroupText } from 'reactstrap';
import ReactSelect from 'react-select';
import { Field, Formik, ErrorMessage } from 'formik';
import FormikDatePicker from 'components/common/Fields/FormikDatePicker';
import * as Yup from 'yup'
import { Option, LimitedChipsContainer, CustomOption } from 'components/Options';
import Calendar from 'assets/icon/calendar-icon.svg';
import useAxios from 'hooks/useAxios';
// import axios from 'axios';
import moment from 'moment';
import { toast } from 'react-toastify';
import CoveredEntity from 'components/CoveredEntity';
import FormikSelectField from 'components/common/Fields/FormikSelectField';
import Loader from 'components/Loader';
import DatePicker from "react-datepicker";
import ReactInputMask from 'react-input-mask';
import "react-datepicker/dist/react-datepicker.css";
import StateManagedSelect from 'react-select';
import EXcelFile from 'assets/images/ReportsType_ExcelFile.xlsx';
import ExcelDownload from 'assets/images/ReportsDownload.svg'
//let ManufacturerData = [];
let pharmacyData = [];

const AddConfigurations = (
  { manvalPharacy,
    onSearch,
    Loading,
    onSave
  }
) => {

  const API = useAxios()
  const { token_type, access_token, profile } =
    JSON.parse(sessionStorage.getItem('pharmaforce')) || {};
  const { roles } = profile || {};
  const steps = [
    { label: '1', content: 'Report Details' },
    { label: '2', content: 'Report Configuration' },
    { label: '3', content: 'Report Settings' },
  ];
  const application = [
    { applicationid: 101, applicationName: 'Contract Pharmacy', encryptedappid: "MjFnUDZITDUweWNqL3plcG9MVmZoZz09" }
  ]

  const ClaimTypes = [
    { claimTypeid: 'A', claimTypeName: 'All' },
    { claimTypeid: 'C', claimTypeName: 'Captured' },
    { claimTypeid: 'NC', claimTypeName: 'Non-Captured' }

  ]

  const initialOptions = [
    { value: 'selectAll', label: 'Select All' },
    // Other options will be added from the API
  ];
  const initialOptions1 = [
    { value: 'selectAll', label: 'Select All' },
    // Other options will be added from the API
  ];
  // pharmacyData=[{ value: 'selectAll', label: 'Select All' }]

  const [currentStep, setCurrentStep] = useState(1);
  const [startDate, setStartdate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [state, setState] = useState(true);
  const [selectedManufactureOption, setmanufactureOptionSelected] = useState({ optionMfSelected: [] });
  const [selectedpharmacyOption, setPharmacyOption] = useState({ optionPhSelected: [] });
  const [coveredEntity, setCoveredEntity] = useState([])
  const [ceid, setceid] = useState()
  const [appid, setAppid] = useState(application[0].encryptedappid)
  const [appID, setAppId] = useState(application[0].applicationid)
  const [reportType, setReportType] = useState([])
  const [reportid, setReportid] = useState('')
  const [reportName, setReportName] = useState('')
  const [claimid, setClaimid] = useState('')
  const [clientId, setClientId] = useState([])
  const [comments, setComments] = useState('')
  const [isChecked, setIsChecked] = useState(false);
  const [frequency, setFrequency] = useState('')
  const [ManufactureIds, setManufactureIds] = useState([])
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [endDateError,setEndDateError]= useState(false)
  const [ManufacturerData, setManufactureData] = useState(initialOptions)
  //const [options, setOptions] = useState(initialOptions)
  const [isSelectAllChecked, setIsSelectAllChecked] = useState(false);
  const [isSelectPHChecked, setIsSelectPHChecked] = useState(false);
  const [pharmacyData1, setPharmacyData1] = useState()

  const handleToggle = () => {
    setIsChecked(!isChecked);
  };
  const Autoselect = isChecked ? 'Y' : 'N'


  let hasDatePopupOpen = false;

  // const nextStep = () => {
  //   if (currentStep < steps.length - 1) {
  //     setCurrentStep(currentStep + 1);
  //   }
  // };

  const prevStep = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };


  const handelCancel = () => {
    setShowDetails(true)
  }
  const handleMouseLeave = () => {
    hasDatePopupOpen = false;
  };

  // const handleStartDate = (startDate) => {
  //     if (startDate !== null) {
  //        setStartdate(new Date(startDate))
  //     }
  //     else {
  //         setStartdate(null);
  //         setError(true)

  //     }
  // };
  const handleStartDate = (startDate) => {
    if (startDate !== null) {
      const date = moment(startDate, 'MM/DD/YYYY', true);
      if (date.isValid()) {
        setStartdate(date.toDate());
      }
    } else {
      setStartdate(null);
    }
  };

  const handleStartDateRaw = (e) => {
    var val = e.target.value;
    if (val == '__/__/____' && startDate !== null) {
      setStartdate(null);
    }

    if (val !== undefined && val.length == 10 && val.indexOf("_") == -1) {
      handleStartDate(e.target.value);
    }
  }


  const handleDateClick = (id) => {
    if (hasDatePopupOpen) {
      hasDatePopupOpen = false;
      return;
    }
    const calendarButton = document.getElementById(id);
    if (calendarButton) {
      calendarButton.click();
      hasDatePopupOpen = true;
    }
  };

  //   const handleEndDate = (endDate) => {
  //     if(endDate){
  //   setEndDate(new Date(endDate))
  //     }
  //     else{
  //         setEndDate(null)
  //     }
  // };

  const handleEndDate = (endDate) => {
    if (endDate !== null) {
      const date = moment(endDate, 'MM/DD/YYYY', true);
      if (date.isValid()) {
        setEndDate(date.toDate());
      }
    } else {
      setEndDate(null);
    }
  };

  const handleEndDateRaw = (e) => {
    var val = e.target.value;
    if (val == '__/__/____' && endDate !== null) {
      setEndDate(null);
      setEndDateError(false)
    }

    if (val !== undefined && val.length == 10 && val.indexOf("_") == -1) {
      handleEndDate(e.target.value);
    }
  }

  // const handleEndDate = (endDate) => {
  //   if(endDate){
  //   setEndDate(new Date(endDate))
  //   }
  //   else{
  //     setEndDate('')
  //   }
  // };

  const onFrequencyChange = (value) => {
    if (value) {
      setFrequency(value)
    }
  }




  const numSteps = 3;

  const nextStep = () => {
    if (currentStep === 1) {
      if (reportName == '' || reportid == '' || reportid == 'undefined' || appid == '' || appid == undefined || ceid == '' || ceid == undefined) {
        setError(true)
      }
      else {
        setError(false)
        setCurrentStep((prevStep) => (prevStep < numSteps && prevStep + 1));
      }
    };
    if (currentStep === 2) {
      if (frequency == '' || frequency == undefined || startDate == '' || startDate == undefined || ManufactureIds == '' || ManufactureIds == undefined
        || clientId == '' || clientId == undefined || claimid == '' || claimid == 'undefined' || (endDate !== null && startDate > endDate)) {
        setError(true)
        if (endDate !== null && startDate > endDate) {
          // Show specific error for end date being less than start date
          setEndDateError(true);
        } else {
          setEndDateError(false);
        }
      }
      else {
        setError(false)
        setCurrentStep((prevStep) => (prevStep < numSteps && prevStep + 1));
      }
    }
  }

  const handleDiscard = () => {
    if (currentStep === 1) {
      manvalPharacy();
    }
    else {
      setCurrentStep(currentStep - 1);
    }
  };

  const [focusedInput, setFocusedInput] = useState('');

  const handleFocus = (inputName) => {
    setFocusedInput(inputName);
  };

  const handleBlur = () => {
    setFocusedInput('');
  };

  const handleComments = (e) => {
    setComments(e.target.value)
  }

  const handelNetClaim = () => {
    setState(!state)
  }
  const netClaimValue = state ? 'Y' : 'N'


  useEffect(() => {
    fetchManufacturer();
    fetchCoveredEntities();
    fetchPharmacy();
    // fetchPharmacy();
  }, [])

  useEffect(() => {
    fetchReportType();
  }, [appid])

  const fetchManufacturer = async () => {
    setLoading(true)
    try {
      const { data } = await API.get('api/CP/manufacturers'
        , {
          hasDefaultURL: true,
        }
      );
      setLoading(false)
      // console.log(data,"data")
      // let val = data
      //     if (Array.isArray(val)) {
      //         setManufacture(val);
      if (data) {
        for (var x in data) {
          ManufacturerData.push({ "value": data[x].manufacturerId, "label": data[x].manufacturerName });
          //   const allOptions = [{ value: '0', label: 'Select All' }, ...ManufacturerData];
          // setOptions(allOptions);

          // ManufacturerData.push({ "value": data[x].manufacturerId, "label": data[x].manufacturerName });

        }
      }
      else {
        setManufactureData([])
      }
    } catch (error) {
      'failed to load data'
    }
  };





  const onManufactureSelect = (selected) => {
    let newSelectedOptions;
    if (selected && selected.some(option => option.value === 'selectAll')) {
      if (selectedManufactureOption.optionMfSelected.length === ManufacturerData.length - 1) {
        newSelectedOptions = [];
      } else {
        newSelectedOptions = ManufacturerData.filter(option => option.value !== 'selectAll');
      }
    } else {
      newSelectedOptions = selected;
    }

    setmanufactureOptionSelected({ optionMfSelected: newSelectedOptions });

    const newManufactureIds = newSelectedOptions.map(option => option.value);
    setManufactureIds(newManufactureIds);

    // Update "Select All" checkbox state
    setIsSelectAllChecked(newSelectedOptions.length === ManufacturerData.length - 1);
  };



  const fetchCoveredEntities = async () => {
    setLoading(true)
    try {
      const { data } = await API.get('api/CP'
        , {
          hasDefaultURL: true,
        }
      );
      if (data) {
        setCoveredEntity(data)
        setLoading(false)
      }
    } catch (error) {
      'failed to load data'
      setLoading(false)
    }
  };

  const coveredname = coveredEntity.find((item) => item.ceid == ceid)
  const CEname = coveredname ? coveredname.ceName : '';
  const OPAcode = coveredname ? coveredname.opaCode : ''

  const handleAppChange = (e) => {
    const { value } = e.target;
    if (value) {
      setAppid(parseInt(value))
      fetchReportType(value)
    }
    else {
      setAppid('')
      setReportid('')
      setReportType([])
    }
  }

  const fetchReportType = async () => {
    setLoading(true)
    try {
      const { data } = await API.get(`api/Report/types/${appid}`
        , {
          hasDefaultURL: true,
        }
      );
      setLoading(false)
      if (data) {
        setReportType(data)
      }

    } catch (error) {
      'failed to load data'
      setLoading(false)
    }
  };


  const fetchPharmacy = async (encryptedCEID) => {
    try {
      const { data } = await API.get(`api/CP/pharmacies/${encryptedCEID}`
        , {
          hasDefaultURL: true,
        }
      )

      if (data) {
        for (var x in data) {
          pharmacyData.push({ "value": data[x].clientID, "label": data[x].phName });
          const allOptions = [{ value: 'selectAll', label: 'Select All' }, ...pharmacyData];
          setPharmacyData1(allOptions);

          // setPharmacyData({ "value": data[x].clientID, "label": data[x].phName })
        }

      }
      else {
        setPharmacyData1([])
      }
    } catch (error) {
      'failed to load data'
      setPharmacyData1([])

    }
  };

  const handleCeChange = (e) => {
    pharmacyData = ([])
    setIsSelectPHChecked(false)
    setPharmacyOption({ optionPhSelected: [] })
    let encryptedCEID = ""
    const { value } = e.target;
    const encryptedceid = coveredEntity.filter((item) => item.ceid == value);
    if (encryptedceid.length > 0) {
      encryptedCEID = encryptedceid[0].encryptedCEID
    }
    if (encryptedCEID) {
      setPharmacyData1([])
      setceid(parseInt(value));
      fetchPharmacy(encryptedCEID);
    }
    if (value == '') {
      setError(true)
      setceid('')
    }
    // else{
    // setceid(parseInt(value));
    // fetchPharmacy(encryptedCEID,setPharmacyData1 );
    // }
  };

  const onPharamcyChange = (selected) => {
    let newSelectedOptions;
    if (selected && selected.some(option => option.value === 'selectAll')) {
      if (selectedpharmacyOption.optionPhSelected.length === pharmacyData1.length - 1) {
        newSelectedOptions = [];
      } else {
        newSelectedOptions = pharmacyData1.filter(option => option.value !== 'selectAll');
      }
    } else {
      newSelectedOptions = selected;
    }

    setPharmacyOption({ optionPhSelected: newSelectedOptions });

    const newPhamarcyIds = newSelectedOptions.map(option => option.value);
    setClientId(newPhamarcyIds);

    // Update "Select All" checkbox state
    setIsSelectPHChecked(newSelectedOptions.length === pharmacyData1.length - 1);
  };

  // const onPharamcyChange = (selected) => {
  //   if (selected) {
  //     let selectedvalue = selected.map(val => val['value'])
  //     setPharmacyOption({
  //       optionPhSelected: selected
  //     });
  //     let clientids = []
  //     clientids.push(selectedvalue)
  //     setClientId(clientids)

  //   }
  // }



  const handleReportChange = (e) => {
    const { value } = e.target;
    if (value) {
      setReportid(parseInt(value));
    }
    else {
      setReportid('')
      setError(true)
    }
  }


  const handleReportName = (e) => {
    setReportName(e.target.value)
  }

  const handleClaimTypeChange = (e) => {
    const { value } = e.target
    if (value) {
      setClaimid(value)
    }
    else {
      setClaimid('')
      setError(true)
    }
  }

  const handleResponse = (response) => {
    if (response.status === 200) {
      toast.success('Configured Report Added Successfully');
    } else {
      alert('Failed to add configurations');
    }
  };
  const fetchConfiguredCoveredEntities = async () => {
    setLoading(true)
    try {
      const { data } = await API.get('api/CP/configured'
        , {
          hasDefaultURL: true,
        }
      );
      setLoading(false)
      if (data) {
        setCoveredEntityData(data)
      }
    } catch (error) {
      'failed to load data'
      setLoading(false)
    }
  };
  const handleSave = async () => {
    let values = {};
    values.ApplicationId = appid,
      values.coveredEntityID = ceid
    // const selectedEndDate= endDate.setUTCHours(0,0,0,0).toISOString()
    //let setdate=startDate+1
    // let setDate = new Date(startDate);
    // setDate.setDate(startDate?.getDate() + 1);
    // let isoDate = setDate.toISOString();
    const year = startDate.getFullYear();
    const month = (startDate.getMonth() + 1).toString().padStart(2, '0');
    const day = startDate.getDate().toString().padStart(2, '0');
    const hours = startDate.getUTCHours().toString().padStart(2, '0');
    const minutes = startDate.getUTCMinutes().toString().padStart(2, '0');
    const seconds = startDate.getUTCSeconds().toString().padStart(2, '0');
    const milliseconds = startDate.getUTCMilliseconds().toString().padStart(3, '0');
    let StartDateUTC = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`
    //let isoEndDate=""
    let EndDateUTC = null
    if (endDate) {
      // let setendDate= new Date(endDate);
      // setendDate.setDate(endDate.getDate()+1);
      // isoEndDate= setendDate?.toISOString();
      const Endyear = endDate.getFullYear();
      const Endmonth = (endDate.getMonth() + 1).toString().padStart(2, '0');
      const Endday = endDate.getDate().toString().padStart(2, '0');
      const Endhours = endDate.getUTCHours().toString().padStart(2, '0');
      const Endminutes = endDate.getUTCMinutes().toString().padStart(2, '0');
      const Endseconds = endDate.getUTCSeconds().toString().padStart(2, '0');
      const Endmilliseconds = endDate.getUTCMilliseconds().toString().padStart(3, '0');
      EndDateUTC = `${Endyear}-${Endmonth}-${Endday}T${Endhours}:${Endminutes}:${Endseconds}.${Endmilliseconds}Z`
    }


    var payload = {}
    payload.reportConfigID = 0,
      payload.ceid = parseInt(ceid),
      payload.ceName = CEname,
      payload.opaCode = OPAcode,
      payload.reportName = reportName,
      payload.reportTypeID = parseInt(reportid),
      payload.frequency = frequency,
      payload.claimType = claimid,
      payload.startDate = StartDateUTC,
      // payload.endDate = isoEndDate?isoEndDate:endDate,
      payload.endDate = EndDateUTC
    payload.netClaims = netClaimValue,
      payload.autoSend = Autoselect,
      payload.comments = comments,
      payload.mfgIDs = ManufactureIds,
      payload.clientIDs = clientId,
      payload.applicationID = parseInt(appID)

    setLoading(true)
    try {
      const response = await API.post(`api/Report/add`, payload,
        {
          hasDefaultURL: true,
        })

      handleResponse(response);
      setLoading(false)
      onSave();
      //fetchConfiguredCoveredEntities();

    }
    catch (error) {
      setLoading(false);
    }
    manvalPharacy();
    //await onSearch(values);
    setLoading(true)
  }

  // const custumOptions=[{
  //    value: 'selectAll', label: 'Select All' },...options
  // ]


  return (
    <Formik
      children={(props) => {
        const { setFieldValue } = props;
        return (
          <div>
            {Loading && <Loader opacity={0.01} color='black' />}
            <Card className='' style={{ height: "calc(100vh - 100px)", background: "transparent", border: "none" }}>
              <CardBody>

                <div>
                  <div className='step_info'>
                    <div className="stepper">
                      <div>
                        <div className="stepper-horizontal" id="stepper1">
                          {Array.from({ length: numSteps }, (_, index) => {
                            const stepNum = index + 1;
                            return (
                              <div
                                className={`step ${stepNum === currentStep ? 'editing fffff' : ''} ${stepNum < currentStep ? 'done' : ''}`}
                                key={stepNum}
                              >
                                <div className="step-circle">
                                  <span>{stepNum}</span>
                                </div>
                                <div className="step-title">
                                  {
                                    stepNum === 1 ? 'Report Details' :
                                      stepNum === 2 ? 'Report Configuration' :
                                        stepNum === 3 ? 'Report Settings' : ''
                                  }
                                </div>

                                {/* Only show content for the active step */}


                                <div className="step-bar-left"></div>
                                <div className="step-bar-right"></div>
                              </div>
                            );
                          })}
                        </div>
                        <div>
                          {currentStep && (
                            <div className="step-content">
                              {currentStep === 1 &&
                                <div>
                                  <div className="d-flex justify-content-between section-devider-border">
                                    <h3 className='font-20'>Report Details</h3>
                                  </div>
                                  <Row className='d-flex pt-3' style={{}}>
                                    <Col md={6} lg={6} sm={6} xs={6}  >
                                      {' '}
                                      <FormGroup>
                                        <Label
                                          htmlFor="application" for='reportname'
                                          className={focusedInput === 'application' ? 'label-focus' : 'required'}
                                        >Application <span>*</span></Label>
                                        <Field
                                          name='Application'
                                          type='text'
                                          disabled
                                          value={appid}
                                          onChange={(e) => handleAppChange(e)}
                                          component={FormikSelectField}
                                          options={application}
                                          defaultoption={application[0].applicationName}
                                          keys={{
                                            id: 'applicationid',
                                            label: 'applicationName',
                                          }}
                                        />
                                        {(error) && (appid == '' || appid == undefined) && <div className="text-danger f-12">
                                          Application id is required </div>}
                                      </FormGroup>
                                    </Col>
                                    <Col md={6} lg={6} sm={6} xs={6}  >
                                      {' '}
                                      <FormGroup>

                                        <Label
                                          htmlFor="coveredentity" for='reportname'
                                          className={focusedInput === 'coveredentity' ? 'label-focus' : 'required'}
                                        >Covered Entity /OPAID <span>*</span></Label>
                                        <Field
                                          className="required"
                                          name='covereEntity'
                                          type='text'

                                          value={ceid}
                                          onChange={(e) => handleCeChange(e)}
                                          component={FormikSelectField}
                                          options={coveredEntity}
                                          onFocus={() => handleFocus('coveredentity')}
                                          onBlur={handleBlur}
                                          defaultoption='--select--'
                                          keys={{
                                            id: 'ceid',
                                            label: 'ceName',
                                          }}
                                        />
                                        {(error) && (ceid == '' || ceid == undefined) && <div className="text-danger f-12">
                                          Covered Entity name is required </div>}
                                      </FormGroup>
                                    </Col>

                                    <Col md={6} lg={6} sm={6} xs={6}  >
                                      {' '}
                                      <FormGroup>

                                        <Label
                                          htmlFor="ReportName" for='reportname'
                                          className={focusedInput === 'ReportName' ? 'label-focus' : 'required'}
                                        >Report Name <span>*</span></Label>
                                        <Input
                                          className="required iconremove"
                                          id="reportName"
                                          name="reportName"
                                          type="text"
                                          //className="required"
                                          placeholder="Enter Report name"
                                          autoComplete="off"
                                          value={reportName}
                                          onFocus={() => handleFocus('ReportName')}
                                          onBlur={handleBlur}
                                          //component={FormikInputField}
                                          onChange={handleReportName}
                                        // onChange={(e) => { handleInvoiceNumberOnChange(e, setFieldValue) }}
                                        />
                                        {(error) && (reportName == '') && <div className="text-danger f-12">
                                          Free Text is Required</div>}
                                      </FormGroup>
                                    </Col>

                                    <Col md={6} lg={6} sm={6} xs={6}  >
                                      {' '}
                                      <FormGroup>
                                        <Label for='reportname'></Label>
                                        <Label
                                          htmlFor="reporttype" for='reportname'
                                          className={focusedInput === 'reporttype' ? 'label-focus' : 'required'}
                                        >Report Type <span>*</span></Label>
                                          <a  style={{float:"right"}} href={EXcelFile} download><img src={ExcelDownload}/></a>
                                        <Field
                                          className="required"
                                          name='ReportType'
                                          type='text'
                                          value={reportid}
                                          onChange={handleReportChange}
                                          component={FormikSelectField}
                                          options={reportType}
                                          onFocus={() => handleFocus('reporttype')}
                                          onBlur={handleBlur}
                                          defaultoption='--select--'
                                          keys={{
                                            id: 'reportTypeID',
                                            label: 'reportTypeName',
                                          }}
                                        />{(error) && (reportid == '' || reportid == undefined) && <div className="text-danger f-12">
                                          Report Type is required </div>}

                                      </FormGroup>
                                    </Col>

                                  </Row>
                                </div>
                              }
                              {currentStep === 2 &&
                                <div>
                                  <div className="d-flex justify-content-between section-devider-border">
                                    <h3 className='font-20'>Report Configurations</h3>
                                  </div>
                                  <div className='re_con_daily pt-3'>
                                    <Label for='Frequency' style={{ display: "block" }}
                                     htmlFor="StartDate"
                                     className={focusedInput === 'Frequency' ? 'label-focus' : 'required'} 
                                    >Frequency <span>*</span></Label>
                                    <ButtonGroup aria-label="Basic example" className='mb-3' 
                                    onFocus={() => handleFocus('Frequency')}
                                                         onBlur={handleBlur}
                                    >
                                      <Button varient="primary" className={frequency === 'DL' ? "active" : ''}
                                        onClick={() => onFrequencyChange('DL')}>Daily</Button>
                                      <Button varient="primary" className={frequency === 'WL' ? "active" : ''}

                                        onClick={() => onFrequencyChange('WL')}>Weekly</Button>
                                      <Button varient="primary" className={frequency === 'BI' ? "active" : ''}

                                        onClick={() => onFrequencyChange('BI')}>Bi-Weekly</Button>
                                    </ButtonGroup>
                                    <div style={{ marginTop: '-15px', marginBottom: '15px' }}>
                                      {(error) && (frequency == '' || frequency == undefined) && <div className="text-danger f-12">
                                        Frequency is required </div>}
                                    </div>
                                    <div>
                                      <div >
                                        <Row className='d-flex' style={{}}>


                                          <Col md={3} lg={6} sm={6} xs={6} className='required-date'>
                                            <FormGroup>

                                              <Label
                                                htmlFor="StartDate" for='reportname'
                                                className={focusedInput === 'StartDate' ? 'label-focus' : 'required'}
                                              >Start Date <span>*</span></Label>
                                              <InputGroup onMouseLeave={handleMouseLeave}
                                             >
                                                {/* <Field
                                                        setcss="required"
                                                        type='text'
                                                        id='startDate'
                                                        name='startDate'
                                                        autoComplete='off'
                                                        placeholder='Select a date'
                                                        value={startDate? new Date(startDate):''}
                                                        component={FormikDatePicker}
                                                        onChange={handleStartDate}
                                                        onFocus={() => handleFocus('StartDate')}
                                                        onBlur={handleBlur}
                                                      /> */}
                                                <DatePicker                                   
                                                  id='StartDate'
                                                  name='StartDate'
                                                  className='required1'
                                                  portalId='root portal'
                                                  onFocus={() => handleFocus('StartDate')}
                                                         onBlur={handleBlur}
                                                  selected={startDate}
                                                  onChange={handleStartDate}
                                                  onChangeRaw={handleStartDateRaw}
                                                  dateFormat="MM/dd/yyyy"
                                                  placeholderText='Select a date'
                                                  autoComplete='off'
                                                  customInput={
                                                    <ReactInputMask mask="99/99/9999"
                                                      value={startDate}
                                                    // maskChar=''
                                                    />

                                                  }
                                                />
                                                <InputGroupText
                                                  onClick={() => handleDateClick('StartDate')}
                                                >
                                                  <img src={Calendar} alt='' />
                                                </InputGroupText>
                                              </InputGroup>
                                              {(error) && (startDate == '' || startDate == undefined) && <div className="text-danger f-12">
                                                Start Date is required </div>}
                                            </FormGroup>
                                          </Col>

                                          <Col md={3} lg={6} sm={6} xs={6} className='required-date'>
                                            <FormGroup>

                                              <Label
                                                htmlFor="EndDate" for='reportname'
                                                className={focusedInput === 'EndDate' ? 'label-focus' : ''}
                                              >End Date</Label>
                                              <InputGroup onMouseLeave={handleMouseLeave}>
                                                {/* <Field
                                                        setcss="required"
                                                        type='text'
                                                        id='startDate'
                                                        name='startDate'
                                                        autoComplete='off'
                                                        placeholder='Select a date'
                                                        minDate={startDate}
                                                        value={endDate}
                                                        component={FormikDatePicker}
                                                        onChange={handleEndDate}
                                                        onFocus={() => handleFocus('EndDate')}
                                                        onBlur={handleBlur}
                                                      /> */}
                                                <DatePicker

                                                  id='EndDate'
                                                  name='EndDate'
                                                  onFocus={() => handleFocus('EndDate')}
                                                         onBlur={handleBlur}
                                                  minDate={startDate}
                                                  selected={endDate}
                                                  portalId='root portal'
                                                  onChange={handleEndDate}
                                                  onChangeRaw={handleEndDateRaw}
                                                  dateFormat="MM/dd/yyyy"
                                                  placeholderText='Select a date'
                                                  autoComplete='off'
                                                  customInput={
                                                    <ReactInputMask mask="99/99/9999"
                                                      value={endDate}
                                                    // maskChar=''
                                                    />

                                                  }
                                                />
                                                <InputGroupText
                                                  onClick={() => handleDateClick('EndDate')}
                                                >
                                                  <img src={Calendar} alt='' />
                                                </InputGroupText>
                                              </InputGroup>
                                              {(endDateError) && (startDate > endDate) ? <div className="text-danger f-12">
                                                End date should be greater than start Date or Null</div> : ''}
                                            </FormGroup>
                                          </Col>
                                          <Col md={3} lg={6} sm={6} xs={6}>
                                            {' '}
                                            <FormGroup>

                                              <Label
                                                htmlFor="Manufacturer" for='reportname'
                                                className={focusedInput === 'Manufacturer' ? 'label-focus' : 'required'}


                                              >Manufacturer <span>*</span></Label>
                                              <ReactSelect
                                                name='Manufacturer'
                                                id='Manufacturer'
                                                options={ManufacturerData}
                                                // options={options}
                                                isMulti
                                                closeMenuOnSelect={false}
                                                hideSelectedOptions={false}
                                                optionSelected={true}
                                                onFocus={() => handleFocus('Manufacturer')}
                                                onBlur={handleBlur}
                                                type='checkbox'
                                                components={{
                                                  Option: CustomOption, ValueContainer: LimitedChipsContainer
                                                }}
                                                isSearchable="true"
                                                //checked={isSelectAllChecked}
                                                onChange={onManufactureSelect}
                                                value={selectedManufactureOption.optionMfSelected}

                                                className='claim_info'
                                                maxMenuHeight={220}
                                               
                                              //  menuPlacement='auto'
                                               // memuPosition='fixed'
                                                styles={{
                                                  menuPortal:(provided)=>({
                                                    ...provided,
                                                    zIndex:9999,
                                                  }),
                                                  menu: (provided) => ({
                                                    ...provided,
                                                    zIndex: 999,
                                                    overflow:'visible'
                                                  }),
                                                 option:(provided)=>({
                                                    ...provided,
                                                    //display:'flex',
                                                   //alignItems:'flex',
                                                    // whiteSpace:'normal',
                                                    // wordBreak:'break-word',
                                                    // alignItems:"center"
                                                    backgroundColor:'transparent'
                                                    
                                                   })
                                                }}
                                                menuPortalTarget={document.body}
                                                isSelectAllChecked={isSelectAllChecked}
                                              />

                                              {(error) && (ManufactureIds == '' || ManufactureIds == undefined) && <div className="text-danger f-12">
                                                Manufacturer selection is required </div>}
                                            </FormGroup>
                                          </Col>

                                          <Col md={3} lg={6} sm={6} xs={6}>
                                            {' '}
                                            <FormGroup>
                                              <Label for='coveredentity'></Label>
                                              <Label
                                                htmlFor="Pharmacy" for='reportname'
                                                className={focusedInput === 'Pharmacy' ? 'label-focus' : 'required'}
                                              >Pharmacy <span>*</span></Label>
                                              <ReactSelect
                                                name='coveredentity'
                                                id='coveredentity'
                                                options={pharmacyData1}
                                                isMulti
                                                closeMenuOnSelect={false}
                                                hideSelectedOptions={false}
                                                optionSelected={true}
                                                onFocus={() => handleFocus('Pharmacy')}
                                                onBlur={handleBlur}
                                                components={{
                                                  Option: CustomOption, ValueContainer: LimitedChipsContainer
                                                }}
                                                isSearchable="true"
                                                value={selectedpharmacyOption.optionPhSelected}
                                                onChange={onPharamcyChange}
                                                className='claim_info ph'
                                                maxMenuHeight={220}
                                                styles={{
                                                  menuPortal:(provided)=>({
                                                    ...provided,
                                                    zIndex:9999,
                                                  }),
                                                  menu: (provided) => ({
                                                    ...provided,
                                                    zIndex: 99,
                                                  }),
                                                
                                                 option:(provided)=>({
                                                    ...provided,
                                                    //display:'flex',
                                                   //alignItems:'flex',
                                                    // whiteSpace:'normal',
                                                    // wordBreak:'break-word',
                                                    // alignItems:"center"
                                                    backgroundColor:'transparent'
                                                    
                                                   })
                                                }}
                                                menuPortalTarget={document.body}
                                                isSelectAllChecked={isSelectPHChecked}
                                              />
                                              {(error) && (clientId == '' || clientId == undefined) && <div className="text-danger f-12">
                                                Pharmacy is required </div>}
                                            </FormGroup>
                                          </Col>


                                          <Col md={3} lg={6} sm={6} xs={6}  >
                                            {' '}

                                            <FormGroup className='controls'>
                                              <Label for='reportname'></Label>
                                              <Label
                                                htmlFor="ClaimType" for='reportname'
                                                className={focusedInput === 'ClaimType' ? 'label-focus' : 'required'}
                                              >Claim Type <span>*</span></Label>
                                              <Field
                                              className="required"
                                                name='ClaimType'
                                                type='text'
                                                value={claimid}
                                                onChange={(e) => handleClaimTypeChange(e)}
                                                component={FormikSelectField}
                                                options={ClaimTypes}
                                                onFocus={() => handleFocus('ClaimType')}
                                                onBlur={handleBlur}
                                                defaultoption='--select--'
                                                keys={{
                                                  id: 'claimTypeid',
                                                  label: 'claimTypeName',
                                                }}
                                              />
                                              {(error) && (claimid == '' || claimid == undefined) && <div className="text-danger f-12">
                                                Claims Type is required </div>}
                                            </FormGroup>
                                          </Col>

                                        </Row>

                                        <Row>
                                          <Col md={3} lg={6} sm={6} xs={6}>

                                            <div className='d-flex justify-content-between align-items-center re_con'>
                                              <div>
                                              <Label className={focusedInput === 'NetClaims' ? 'label-focus' : ''} style={{ fontSize: "16px" }}>Net Claims 
                                               </Label>
                                               <span  className='' style={{ display: "block" }}
                                              >If this is Enabled it only sends <br />
                                                B1 net claims only.</span>
                                                </div>
                                              <FormGroup switch style={{ paddingRight: "15px" }} className='form-check form-switch'>

                                                <Input
                                                  className='form-check-input'
                                                  onFocus={() => handleFocus('NetClaims')}
                                                         onBlur={handleBlur}
                                                  type="switch"
                                                  checked={state}
                                                  onClick={handelNetClaim}
                                                />

                                              </FormGroup>
                                            </div>

                                          </Col>
                                        </Row>
                                      </div>













                                    </div>



                                  </div>
                                </div>
                              }
                              {currentStep === 3 &&
                                <div>
                                  <div className="d-flex justify-content-between section-devider-border">
                                    <h3 className='font-20'>Report Settings</h3>
                                  </div>

                                  {/* <Col md={6} lg={12} sm={6} xs={6} className='pt-3'> */}
                                  {/* <FormGroup>
                                      <Label for='reportname'>Auto Send</Label>
                                      <label className='toggle-label'>
                                        <input
                                          type='checkbox'
                                          id="hrsamedicaid"
                                          checked={isChecked}
                                          disabled={!roles?.includes("SUPADM")}
                                          onChange={handleToggle}
                                        />
                                        <span className='back'>
                                          <span className='toggle'></span>
                                          <span className='label on'>OFF</span>
                                          <span className='label off'>ON</span>
                                        </span>
                                      </label>
                                    </FormGroup> */}
                                  <Col md={3} lg={6} sm={6} xs={6} className='pt-3' >
                                    <div className='d-flex justify-content-between align-items-center re_con'>
                                      <Label  className={focusedInput === 'AutoSend' ? 'label-focus' : 'required'} style={{ fontSize: "16px" }}>Auto Send
                                      </Label>
                                      <FormGroup switch style={{ paddingRight: "15px" }} className='form-check form-switch'>

                                        <Input
                                          className='form-check-input'
                                          type="switch"
                                          onFocus={() => handleFocus('AutoSend')}
                                          onBlur={handleBlur}
                                          disabled={!roles?.includes("SUPADM")}
                                          checked={isChecked}
                                          onClick={handleToggle}
                                        />

                                      </FormGroup>
                                    </div>
                                  </Col>

                                  <Col md={6} lg={12} sm={6} xs={6} className='pt-3' >
                                    {' '}

                                    <FormGroup className='controls textarea_info'>
                                      <Label  className={focusedInput === 'Comments' ? 'label-focus' : ''} for='reportname'>Comments Optional</Label>
                                      <Input
                                        id='reportname'
                                        name='reportname'
                                        type='textarea'
                                        placeholder="Add comment about report"
                                        onFocus={() => handleFocus('Comments')}
                                        onBlur={handleBlur}
                                        value={comments}
                                        onChange={handleComments}
                                        autoComplete='off'
                                      >


                                      </Input>
                                    </FormGroup>
                                  </Col>

                                </div>
                              }
                            </div>
                          )}
                        </div>



                      </div>

                    </div>


                  </div>

                </div>

              </CardBody>
            </Card>

            <div>
              <footer>
                <div hidden={""}>
                  <Row>
                    <Col sm={0} className="d-flex" style={{ justifyContent: "flex-end" }}>
                      <Label>{""}</Label>
                      <Button
                        // hidden={isAddPermission}
                        // disabled={isSubmitting}
                        color="primary"
                        className="reset"
                        onClick={handleDiscard}

                      // disabled={!coveredEntityid}
                      // hidden={!hasPermission("ADD")}
                      >

                        Discard
                      </Button>
                      {currentStep > 2 ?
                        <Button
                          color="primary"
                          className="search"
                          onClick={handleSave}
                        >
                          Save
                        </Button>
                        :
                        <Button
                          // disabled={currentStep == 3}
                          // hidden={isAddPermission}
                          // disabled={isSubmitting}
                          color="primary"
                          className="search"
                          onClick={nextStep}

                        // disabled={!coveredEntityid}
                        // hidden={!hasPermission("ADD")}
                        >

                          Next
                        </Button>
                      }
                    </Col>
                  </Row>
                </div>

                {/* <div className="stepper-footer">
        <button onClick={prevStep} disabled={currentStep === 0}>
          Previous
        </button>
        <button onClick={nextStep} disabled={currentStep === steps.length - 1}>
          Next
        </button>
      </div> */}
              </footer>




            </div>
          </div>


        )
      }
      }
    ></Formik>
  )
}

export default AddConfigurations